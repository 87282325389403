import {
  useState,
  useEffect,
} from 'react';
import {
  parse,
  format,
  isBefore,
  isAfter,
} from 'date-fns';
import { useContext } from 'react';
import { MenuContext } from 'contexts/menu';
import ReactLoading from 'react-loading';
import {
  listExperiments,
  getUrlOfExperimentResults,
  getUrlOfExperimentResultsV2,
  handleSelectOptions,
} from 'services/api';
import './index.scss';
import Text from 'components/Text';
import { Select } from 'components/Select';
import Button from 'components/Button';
import DatePicker from 'components/Date';
import { RiFileTextLine } from 'react-icons/ri';
import { formatDate, getRootVar } from 'utils/generalFunctions';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import axios from 'axios';
import DataTable from 'components/Table';
import { toast } from 'react-toastify';
import Modal from 'components/Modal';

const ReportsPage = () => {
  const { t } = useTranslation();

  const analisisType = [
    {
      label: t("Bandeja"),
      value: "tray"
    },
    {
      label: t("Planta"),
      value: "plant"
    },
  ]
  const choiceList = [
    {
      label: t("Sim"),
      value: "true"
    },
    {
      label: t("Não"),
      value: "false"
    },
  ]

  const { selectedCompany, selectedGreenhouse } = useContext(MenuContext);
  const [selectedAnalisisType, setSelectedAnalisisType] = useState(analisisType[0]);
  const [selectedPlantsNumber, setSelectedPlantsNumber] = useState(choiceList[0]);
  const [selectedImageLink, setSelectedImageLink] = useState(choiceList[1]);
  const [selectedLeafCoverage, setSelectedLeafCoverage] = useState(choiceList[0]);
  const [experiment, setExperiment] = useState("");
  const [loadingReport, setLoadingReport] = useState(false);
  const [selectedLeafAreaIndex, setselectedLeafAreaIndex] = useState(choiceList[1]);
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState(reports);
  const [columns, setColumns] = useState([]);
  const [reportsFileTypeModal, setReportsFileTypeModal] = useState(false);
  const [isLoadingExportCSV, setIsLoadingExportCSV] = useState(false);
  const [isLoadingExportXLSX, setIsLoadingExportXLSX] = useState(false);
  const [allowedScanTimes, setAllowedScanTimes] = useState(false);
  const [selectedScanTime, setSelectedScanTime] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [groupedExperimentOptions, setGroupedExperimentOptions] = useState([])
  const [isLoadingExperiments, setIsLoadingExperiments] = useState([])

  const getThreeMonthsAgo = () => {
    const today = new Date();
    const threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    return threeMonthsAgo;
  };

  const initialRange = [getThreeMonthsAgo(), new Date()];
  const [rangeDate, setRangeDate] = useState(initialRange);

  const makeColumns = (data) => {
    const showTreatmentColumn = data.every((item) => item?.treatment !== "?");
    const showRepetitionColumn = data.every((item) => item?.repetition !== "?");
    const showGliMeanColumn = data.every((item) => item?.gli_mean);
    const showGliStdColumn = data.every((item) => item.gli_std);
    const showGliVarColumn = data.every((item) => item?.gli_var);
    const showImageColumn = data.every((item) => item?.image);
    const showPlantId = data.every((item) => item?.plant_id);

    return setColumns(
      [
        {
          key: 'exp_name',
          name: <Text className="gray-2 f-2 bold">Nome do experimento</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.exp_name ? entity.exp_name : "-"}
            </Text>
          )
        },
        {
          key: 'code',
          name: <Text className="gray-2 f-2 bold">Código da unidade</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.code ? entity.code : "-"}
            </Text>
          )
        },
        showTreatmentColumn ? {
          key: 'treatment',
          name: <Text className="gray-2 f-2 bold">Tratamento</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.treatment}
            </Text>
          )
        } : null,
        showRepetitionColumn && {
          key: 'repetition',
          name: <Text className="gray-2 f-2 bold">Repetição</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.repetition}
            </Text>
          )
        },
        {
          key: 'scan_date',
          name: <Text className="gray-2 f-2 bold">Data do scan</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {formatDate(entity.scan_date ? entity.scan_date : "-")}
            </Text>
          )
        },
        {
          key: 'scan_time',
          name: <Text className="gray-2 f-2 bold">Hora do scan</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.scan_time ? entity.scan_time : "-"}
            </Text>
          )
        },
        showGliMeanColumn && {
          key: 'gli_mean',
          name: <Text className="gray-2 f-2 bold">Media</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.gli_mean}
            </Text>
          )
        },
        showGliStdColumn && {
          key: 'gli_std',
          name: <Text className="gray-2 f-2 bold">Desvio padrão</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.gli_std}
            </Text>
          )
        },
        showGliVarColumn && {
          key: 'gli_var',
          name: <Text className="gray-2 f-2 bold">Variança</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.gli_var}
            </Text>
          )
        },
        showPlantId && {
          key: 'plant_id',
          name: <Text className="gray-2 f-2 bold">ID por planta</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.plant_id}
            </Text>
          )
        },
        {
          key: 'plants_number',
          name: <Text className="gray-2 f-2 bold">Número de plantas</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.plants_number ? entity.plants_number : "-"}
            </Text>
          )
        },
        {
          key: 'full_leaf_coverage',
          name: <Text className="gray-2 f-2 bold">Cobertura foliar</Text>,
          render: (entity) => (
            <Text className="gray-2 f-2" autoTranslate={false}>
              {entity.full_leaf_coverage ? entity.full_leaf_coverage : "-"}
            </Text>
          )
        },
        showImageColumn &&
        {
          key: 'image_view',
          name: <Text className="gray-2 f-2 bold">Imagem</Text>,
          render: (entity) => (
            <div className='d-flex align-items-center gap-3'>
              <Button onClick={() => handleDownloadImage(entity.image)} className="secondary small">
                <Text className="small white">Visualizar</Text>
              </Button>
            </div>
          )
        }
      ]
    )
  }


  useEffect(() => {
    if (rangeDate?.length > 1) {
      setIsLoadingExperiments(true)

      async function updateExperiments() {
        const activeData = {};
        const inactiveData = {};

        try {
          const activeRes = await listExperiments(
            selectedCompany?.name,
            selectedGreenhouse?.name,
            true
          );

          for (const exp of activeRes.data) {
            const expStartDate = exp.exp_start_date && parse(exp.exp_start_date, 'yyyyMMdd', new Date());
            const expEndDate = exp.end_date && parse(exp.end_date, 'yyyyMMdd', new Date());

            if (
              (expStartDate && isAfter(expStartDate, rangeDate[1])) ||
              (expEndDate && isBefore(expEndDate, rangeDate[0]))
            ) {
              continue;
            }

            const item = {
              ...exp,
              start_date: expStartDate,
              end_date: expEndDate,
              formatted_start_date: format(expStartDate, 'dd/MM/yyyy'),
            };
            activeData[exp.uuid] = item;
          }

          const activeExperimentsArray = Object.values(activeData);

          const activeExperimentsListObj = activeExperimentsArray.sort((a, b) =>
            new Date(b.start_date) - new Date(a.start_date)
          ).map(e => ({
            label: e.name,
            value: e.uuid
          }));

          const inactiveRes = await listExperiments(
            selectedCompany?.name,
            selectedGreenhouse?.name,
            false,
            rangeDate[0],
            rangeDate[1]
          );

          for (const exp of inactiveRes.data) {
            const expStartDate = exp.exp_start_date && parse(exp.exp_start_date, 'yyyyMMdd', new Date());
            const expEndDate = exp.end_date && parse(exp.end_date, 'yyyyMMdd', new Date());

            if (
              (expStartDate && isAfter(expStartDate, rangeDate[1])) ||
              (expEndDate && isBefore(expEndDate, rangeDate[0]))
            ) {
              continue;
            }

            const item = {
              ...exp,
              start_date: expStartDate,
              end_date: expEndDate,
              formatted_start_date: format(expStartDate, 'dd/MM/yyyy'),
            };
            inactiveData[exp.uuid] = item;
          }

          const inactiveExperimentsArray = Object.values(inactiveData);

          const inactiveExperimentsListObj = inactiveExperimentsArray.sort((a, b) =>
            new Date(b.start_date) - new Date(a.start_date)
          ).map(e => ({
            label: e.name,
            value: e.uuid
          }));       

          const groupedOptions = [
            {
              label: t('Experimentos ativos'),
              options: activeExperimentsListObj,
            },
            {
              label: t('Experimentos finalizados'),
              options: inactiveExperimentsListObj,
            }
          ]

          setGroupedExperimentOptions(groupedOptions)
          setIsLoadingExperiments(false)
        } catch (ex) {
          console.error('ex:', ex);
        }
      }
      if (selectedCompany && selectedGreenhouse) {
        updateExperiments();
      }
    }

    async function selectOptions() {
      const allowedScanTimeOptions = await handleSelectOptions(selectedCompany?.name, selectedGreenhouse?.name, 'allowed_scan_times');

      setAllowedScanTimes(allowedScanTimeOptions)
    }

    selectOptions()

  }, [selectedCompany, selectedGreenhouse, rangeDate]);

  function handleDownloadImage(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "image.jpg");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async function handleGenerateReport() {
    setIsReportGenerated(true)
    setLoadingReport(true);

    const includePreviousExperiments = true;
    try {
      const res = await getUrlOfExperimentResultsV2(
        selectedCompany?.name,
        selectedGreenhouse?.name,
        experiment?.value,
        "long",
        selectedAnalisisType?.value, //analisisType
        includePreviousExperiments,
        selectedPlantsNumber.value, //addPlantAmmount,
        selectedLeafCoverage.value, //addLeafAreaIndex,
        selectedImageLink.value, //addImageUrl,
        selectedLeafAreaIndex.value //addGli,
      );

      setReports(res.data.data);
      makeColumns(res.data.data)

      if (selectedScanTime) {
        filterByScanTime(selectedScanTime, res.data.data)
      } else if (filterDate) {
        const filter = res.data.data.filter(report => report.scan_date === format(filterDate, 'dd/MM/yyyy'))
        setFilteredReports(filter);
      } else {
        setFilteredReports(res.data.data);
      }
    } catch (ex) {
      console.error('ex:', ex);
      setLoadingReport(false);
      return;
    }
    setLoadingReport(false);
  }

  const onChange = (value, setValue) => {
    setValue(value);
  };

  const handleExportFile = async (type) => {
    if (type === "csv") {
      setIsLoadingExportCSV(true)
    } else {
      setIsLoadingExportXLSX(true)
    }

    const includePreviousExperiments = true;
    let url = ""

    try {
      const res = await getUrlOfExperimentResults(
        selectedCompany?.name,
        selectedGreenhouse?.name,
        experiment?.value,
        "long",
        type,
        selectedAnalisisType?.value, //analisisType
        includePreviousExperiments,
        selectedPlantsNumber.value, //addPlantAmmount,
        selectedLeafCoverage.value, //addLeafAreaIndex,
        selectedImageLink.value, //addImageUrl,
        selectedLeafAreaIndex.value //addGli,
      );
      url = res.data.data
    } catch (ex) {
      console.error('ex:', ex);
      toast.error(t("Falha ao exportar relatório"))
      setIsLoadingExportXLSX(false)
      setIsLoadingExportCSV(false)
      return;
    }

    const urlSplit = url?.split("/");
    const fileName = urlSplit[urlSplit.length - 1].split("?")[0];
    axios.get(url, {
      responseType: 'blob',
    }).then((res) => {
      fileDownload(res.data, fileName);
    });

    if (type === "csv") {
      setIsLoadingExportCSV(false)
    } else {
      setIsLoadingExportXLSX(false)
    }
  };

  function filterReportsByDate(date) {
    setFilterDate(date)

    if (date) {
      const data = selectedScanTime ? filteredReports : reports
      const filter = data.filter(report => report.scan_date === format(date, 'dd/MM/yyyy'))
      setFilteredReports(filter)
    } else {
      setFilteredReports(reports)
    }
  }

  const filterByScanTime = (selectedScanTime, reportsData) => {
    setSelectedScanTime(selectedScanTime)

    if (selectedScanTime) {
      const filteredData = filterDate && reportsData.filter(obj => obj.scan_date === format(filterDate, 'dd/MM/yyyy'))
      const data = filterDate ? filteredData : reportsData

      const timeToMinutes = (timeStr) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return hours * 60 + minutes;
      };

      const selectedMinutes = timeToMinutes(selectedScanTime.value);

      const response = data.filter(obj => {
        const scanTimeMinutes = timeToMinutes(obj.scan_time);
        return scanTimeMinutes >= selectedMinutes &&
          scanTimeMinutes <= selectedMinutes + 59;
      });

      setFilteredReports(response)
    } else {
      filterReportsByDate(filterDate)
    }
  };

  function handleClearDateFilter() {
    setFilterDate("")
    setFilteredReports(reports)
  }

  function handleClearScanFilter() {
    setSelectedScanTime("")
    if (filterDate) {
      const filter = reports.filter(report => report.scan_date === format(filterDate, 'dd/MM/yyyy'))
      setFilteredReports(filter)
    } else {
      setFilteredReports(reports)
    }
  } 

  return (
    <>
      <div className="ReportsPage">
        <div className="Header">
          <div className="PageNav">
            <Text className="bold big gray2">Relatórios</Text>
          </div>
          {window.innerWidth > 980 && (
            <div className="d-flex align-items-center gap-3">
              <Text onClick={() => experiment && setReportsFileTypeModal(true)} className="smallTxt">Exportar relatório</Text>
              <Button disabled={!rangeDate || !experiment} onClick={handleGenerateReport} className="primary">
                <Text className="bold white">Gerar relatório</Text>
              </Button>
            </div>
          )}
        </div >
        <div className="Content">
          <div className="Group">
            <div className="Inputs">
              <DatePicker
                label={<Text className="bold smallTxt">Período do experimento</Text>}
                placeholder="Selecione o período do experimento"
                value={rangeDate}
                onChange={(date) => onChange(date, setRangeDate)}
                mode="range"
                required
              />
              <Select
                label={<Text className="bold smallTxt">Experimento</Text>}
                disabled={rangeDate?.length < 2}
                options={groupedExperimentOptions}
                value={experiment}
                placeholder="Selecione o experimento"
                onChange={(e) => onChange(e, setExperiment)}
                required
                isLoading={isLoadingExperiments}
              />
              <Select
                label={<Text className="bold smallTxt">Método de análise</Text>}
                options={analisisType}
                value={selectedAnalisisType}
                placeholder="Selecione o tipo de análise"
                onChange={(e) => onChange(e, setSelectedAnalisisType)}
                clear={false}
              />
              <Select
                label={<Text className="bold smallTxt">Cobertura foliar</Text>}
                options={choiceList}
                value={selectedLeafCoverage}
                onChange={(e) => onChange(e, setSelectedLeafCoverage)}
                clear={false}
              />
              <Select
                label={<Text className="bold smallTxt">Número de plantas</Text>}
                options={choiceList}
                value={selectedPlantsNumber}
                onChange={(e) => onChange(e, setSelectedPlantsNumber)}
                clear={false}
              />
              <Select
                label={<Text className="bold smallTxt">Índice vegetativo</Text>}
                options={choiceList}
                value={selectedLeafAreaIndex}
                onChange={(e) => onChange(e, setselectedLeafAreaIndex)}
                clear={false}
              />
              <Select
                label={<Text className="bold smallTxt">Link para imagem</Text>}
                options={choiceList}
                value={selectedImageLink}
                onChange={(e) => onChange(e, setSelectedImageLink)}
                clear={false}
              />
              {window.innerWidth < 980 && (
                <div className="d-flex align-items-center flex-column gap-3" style={{ width: "100%" }}>
                  <Button disabled={!rangeDate || !experiment} onClick={() => experiment && setReportsFileTypeModal(true)} className="secondary fluid"><Text className="bold white">Exportar relatório</Text></Button>
                  <Button disabled={!rangeDate || !experiment} onClick={handleGenerateReport} className="primary fluid">
                    <Text className="bold white">Gerar relatório</Text>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="Content">
          {isReportGenerated ?
            <DataTable
              data={filteredReports}
              itemsPerPage={10}
              noDataMessage={
                <div className="noTableData">
                  <div className='noDataMessage' style={{ width: loadingReport ? "" : "100%" }}>
                    {loadingReport ? <ReactLoading
                      className="ReactLoading"
                      type={"spin"}
                      color={getRootVar("--gray3")}
                      height={25}
                      width={25}
                    /> : <>
                      <Text className="bold">Nenhum resultado encontrado!</Text>
                      <Text className="smallTxt">Revise as inforamações da pesquisa e tente novamente.</Text>
                    </>
                    }
                  </div>
                </div>
              }
              isLoading={loadingReport}
              columns={columns}
              filters={window.innerWidth > 980 ?
                <div className='d-flex align-items-center gap-2' style={{ marginLeft: "12px" }}>
                  <DatePicker
                    placeholder="Filtrar por data"
                    value={filterDate}
                    onChange={(date) => filterReportsByDate(date)}
                    small
                    clear={handleClearDateFilter}
                  />
                  <Select
                    placeholder="Hora do scan"
                    options={allowedScanTimes}
                    value={selectedScanTime}
                    onChange={(e) => filterByScanTime(e, reports)}
                    small
                    clear={handleClearScanFilter}
                  />
                </div>
                :
                <div className='d-flex flex-column align-items-center gap-3'>
                  <DatePicker
                    placeholder="Filtrar por data"
                    value={filterDate}
                    onChange={(date) => filterReportsByDate(date)}
                    clear={handleClearDateFilter}
                  />
                  <Select
                    placeholder="Hora do scan"
                    options={allowedScanTimes}
                    value={selectedScanTime}
                    onChange={(e) => filterByScanTime(e, reports)}
                    clear={handleClearScanFilter}
                  />
                </div>
              }
            />
            :
            <div className="noTableData" style={{ marginTop: "0px" }}>
              <div className='noDataMessage' style={{ width: loadingReport ? "" : "100%" }}>
                {loadingReport ? <ReactLoading
                  className="ReactLoading"
                  type={"spin"}
                  color={getRootVar("--gray3")}
                  height={25}
                  width={25}
                /> : <>
                  <Text className="bold">Nenhum relatório foi gerado!</Text>
                  <Text className="smallTxt">Preencha os campos acima e clique em "Gerar relatório" para aparecer aqui.</Text>
                </>
                }
              </div>
            </div>
          }

        </div>
      </div>
      {reportsFileTypeModal &&
        <Modal title="Exportar relatório"
          handleCloseModal={() => setReportsFileTypeModal(false)}>
          <div className="modalReports">
            <div className="exportReportType" onClick={() => handleExportFile("csv")}>
              {
                isLoadingExportCSV ?
                  <ReactLoading
                    className="ReactLoading"
                    type="spin"
                    color={getRootVar("--gray3")}
                    height={21}
                    width={21}
                  /> :
                  <>
                    <div style={{ width: window.innerWidth > 980 && "100%" }}>
                      <RiFileTextLine size="25" />
                    </div>
                    <Text>Exportar em CSV</Text>
                  </>
              }
            </div>
            <div className="exportReportType" onClick={() => handleExportFile("xlsx")}>
              {
                isLoadingExportXLSX ?
                  <ReactLoading
                    className="ReactLoading"
                    type="spin"
                    color={getRootVar("--gray3")}
                    height={21}
                    width={21}
                  /> :
                  <>
                    <div style={{ width: window.innerWidth > 980 && "100%" }}>
                      <RiFileTextLine size="25" />
                    </div>
                    <Text>Exportar em XLSX</Text>
                  </>
              }
            </div>
          </div>
        </Modal >
      }
    </>
  );
};

export default ReportsPage;
