/* eslint-disable import/no-anonymous-default-export */
export default {
    translations: {
        Claro: "Claro",
        Escuro: "Oscuro",
        Tema: "Tema",
        Idioma: "Idioma",
        "Português (Brasil)": "Portugués (Brasil)",
        "Portuguese (Brazil)": "Portugués (Brasil)",
        "Portugués (Brasil)": "Portugués (Brasil)",
        "Inglês (EUA)": "Inglés (EUA)",
        "English (USA)": "Inglés (EUA)",
        "Inglés (EUA)": "Inglés (EUA)",
        Espanhol: "Español",
        Spanish: "Español",
        Español: "Español",
        Configurações: 'Ajustes',
        Painéis: 'Paneles',
        Galeria: 'Galeria',
        Relatórios: 'Informes',
        Estufa: "Invernadero:",
        Empresa: "Empresa:",
        Selecionar: "Seleccionar",
        "Página inicial": 'Inicio',
        "Pesquisar estufa": 'Buscar invernadero',
        "Nenhum resultado encontrado.": 'No se encontraron resultados.',
        "Experimentos ativos": 'Experimentos activos',
        "Selecione apenas bandejas inativas para criar um novo experimento.": "Seleccione solo bandejas inactivas para crear un nuevo experimento.",
        "Selecione as bandejas antes de criar um novo experimento.": "Seleccione las bandejas antes de crear un nuevo experimento.",
        "Falha ao verificar as solicitações de scan correntes!": "¡Error al verificar las solicitudes de escaneo actuales!",
        "Já existe um scan de mosaico em andamento.": "Ya hay un escaneo de mosaico en curso.",
        "Selecione no mapa as mesas ou estufa para criar o novo mosaico.": "Seleccione en el mapa las mesas o invernadero para crear el nuevo mosaico.",
        "Solicitação bem sucedida! Em breve o mosaico será gerado.": "¡Solicitud exitosa! El mosaico se generará pronto.",
        "Falha ao criar o mosaico": "Error al crear el mosaico",
        'Experimento deletado com sucesso': 'Experimento eliminado con éxito',
        'Não foi possível deletar este experimento': 'No se pudo eliminar este experimento',
        "Selecione pelo menos uma bandeja": "Seleccione al menos una bandeja",
        "Selecione apenas uma bandeja por vez": "Seleccione solo una bandeja a la vez",
        "Selecione apenas bandejas ativas": "Seleccione solo bandejas activas",
        "Código de identificação adicionado!": "¡Código de identificación añadido!",
        "Falha ao adicionar código de identificação": "Error al añadir el código de identificación",
        "Início": "Inicio",
        Nome: "Nombre",
        "Data de finalização": "Fecha de finalización",
        "Editar/Remover": "Editar/Eliminar",
        Editar: "Editar",
        "Novo experimento": "Nuevo experimento",
        "Nenhum experimento encontrado!": "¡No se encontraron experimentos!",
        "Crie um novo experimento para ele aparecer aqui.": "Cree un nuevo experimento para que se muestre aquí.",
        "Gerar mosaico": "Generar mosaico",
        "Você tem certeza que deseja deletar esse experimento? Ele não será mais exibido na lista e no mapa.": "¿Está seguro de que desea eliminar este experimento? Ya no se mostrará en la lista ni en el mapa.",
        "Esta operação sobrescreve a solicitação anterior e é irreversível!": "¡Esta operación sobrescribe la solicitud anterior y es irreversible!",
        "Adicionar código de identificação": "Agregar código de identificación",
        "Editar código de identificação": "Editar código de identificación",
        "Editar código": "Editar código",
        "Adicionar código": "Agregar código",
        "Gerar nova solicitação de scan de mosaico?": "¿Generar una nueva solicitud de escaneo de mosaico?",
        "Gerar nova solicitação": "Generar nueva solicitud",
        'Você está adicionando um código em uma bandeja que faz parte do experimento "{{experimentName}}".': 'Está agregando un código en una bandeja que es parte del experimento "{{experimentName}}".',
        'Você está editando um código em uma bandeja que faz parte do experimento "{{experimentName}}".': 'Está editando un código en una bandeja que es parte del experimento "{{experimentName}}".',
        "Código de identificação": "Código de identificación",
        "Você já possui uma bandeja dentro do experimento \"{{experimentName}}\" com o código de identificação \"{{code}}\".": "Ya tiene una bandeja en el experimento \"{{experimentName}}\" con el código de identificación \"{{code}}\".",
        "Digite o código de identificação": "Ingrese el código de identificación",
        Pesquisar: "Buscar",
        "Deletar experimento": "Eliminar experimento",
        "Editar experimento": "Editar experimento",
        "Cadastrar experimento": "Registrar experimento",
        "Salvar alterações": "Guardar cambios",
        "Dados do experimento": "Datos del experimento",
        "Nome do experimento": "Nombre del experimento",
        "Tipo de experimento": "Tipo de experimento",
        "Tipo de cultivo": "Tipo de cultivo",
        "Tamanho da unidade": "Tamaño de la unidad",
        "Experimento de referência": "Experimento de referencia",
        "Descrição": "Descripción",
        "Dados do monitoramento": "Datos del monitoreo",
        "Data de início": "Fecha de inicio",
        "Horários de varredura": "Horarios de escaneo",
        "Data de plantio": "Fecha de siembra",
        "Digite o nome do experimento": "Escriba el nombre del experimento",
        "Selecione o tipo de experimento": "Seleccione el tipo de experimento",
        "Selecione o tipo de cultivo": "Seleccione el tipo de cultivo",
        "Selecione o tamanho": "Seleccione el tamaño",
        "Selecione o experimento": "Seleccione el experimento",
        "Descreva aqui o experimento": "Describa el experimento aquí",
        "Selecione os horários": "Seleccione los horarios",
        "Selecione a data de plantio": "Seleccione la fecha de siembra",
        "Selecione a data de finalização": "Seleccione la fecha de finalización",
        "Criar experimento": "Crear experimento",
        "Se você sair desta página, as alterações realizadas não serão salvas. Tem certeza de que deseja retornar ao mapa de experimentos?": "Si sale de esta página, los cambios realizados no se guardarán. ¿Está seguro de que desea volver al mapa de experimentos?",
        "Você tem alterações não salvas": "Tiene cambios no guardados",
        "Retornar ao mapa de experimentos": "Volver al mapa de experimentos",
        "Galeria de imagens": "Galería de imágenes",
        "Período do experimento": "Periodo del experimento",
        "Experimento": "Experimento",
        "Tipo de foto": "Tipo de foto",
        "Consultar fotos": "Consultar fotos",
        "Selecione o período do experimento": "Seleccione el periodo del experimento",
        "Cancelar": "Cancelar",
        "Selecionar todos ({{imagesList}})": "Seleccionar todos ({{imagesList}})",
        "Deselecionar todos ({{imagesList}})": "Deseleccionar todos ({{imagesList}})",
        "Baixar imagens ({{selectedNumber}})": "Descargar imágenes ({{selectedNumber}})",
        "Baixar": "Descargar",
        "Sair do sistema": "Salir del sistema",
        "Exportar relatório": "Exportar informe",
        "Gerar relatório": "Generar informe",
        "Método de análise": "Método de análisis",
        "Cobertura foliar": "Cobertura foliar",
        "Número de plantas": "Número de plantas",
        "Índice vegetativo": "Índice vegetativo",
        "Link para imagem": "Enlace para imagen",
        "Nenhum relatório foi gerado!": "¡No se ha generado ningún informe",
        "Nenhum resultado encontrado!": "¡No se encontraron resultados!",
        "Revise as inforamações da pesquisa e tente novamente.": "Revise la información de la búsqueda y vuelva a intentarlo.",
        'Preencha os campos acima e clique em "Gerar relatório" para aparecer aqui.': 'Complete los campos anteriores y haga clic en "Generar informe" para que aparezca aquí.',
        "Sim": "Sí",
        "Não": "No",
        "Exportar em CSV": "Exportar en CSV",
        "Exportar em XLSX": "Exportar en XLSX",
        "Filtar por data": "Filtrar por fecha",
        "Hora do scan": "Hora del escaneo",
        "Código da unidade": "Código de la unidad",
        "Data do scan": "Fecha del escaneo",
        "Bandeja": "Bandeja",
        "Planta": "Planta",
        "Imagem": "Imagen",
        "Variança": "Varianza",
        "Media": "Media",
        "Desvio padrão": "Desviación estándar",
        "Repetição": "Repetición",
        "Tratamento": "Tratamiento",
        "Visualizar": "Visualizar",
        "Filtrar tabela": "Filtrar tabla",
        "Falha ao exportar relatório": "Error al exportar el informe",
        "Experimento destinado à continuidade das avaliações após a aplicação de um tratamento que resultou na movimentação das unidades experimentais de suas posições originais. Os dados do experimento de referência e do atual serão mantidos em conjunto, baseando-se no croqui do experimento, com os códigos inseridos em cada unidade experimental. O número de unidades experimentais no novo experimento deve ser igual ao do experimento de referência, e os códigos utilizados ao inserir o novo croqui devem ser os mesmos do experimento de referência.": "Experimento destinado a la continuidad de las evaluaciones tras la aplicación de un tratamiento que resultó en el desplazamiento de las unidades experimentales de sus posiciones originales. Los datos del experimento de referencia y del actual se mantendrán juntos, basándose en el croquis del experimento, con los códigos insertados en cada unidad experimental. El número de unidades experimentales en el nuevo experimento debe ser igual al del experimento de referencia, y los códigos utilizados al insertar el nuevo croquis deben ser los mismos que los del experimento de referencia.",
        "Pequena: 1 foto": "Pequeña: 1 foto",
        "Média: 4 fotos": "Media: 4 fotos",
        "Você tem {{time}} para editar essa informação. Após esse período, o campo será bloqueado.": "Tienes {{time}} para editar esta información. Después de este período, el campo será bloqueado.",
        "Selecionar tudo ({{listLength}})": "Seleccionar todo ({{listLength}})",
        "ID por planta": "ID por planta",
        "soybean": "soja",
        "beans": "frijol",
        "corn": "maíz",
        "cotton": "algodón",
        "peanut": "maní",
        "sugar cane": "caña de azúcar",
        "wheat": "trigo",
        "other": "otro",
        "germination_test": "prueba de germinación",
        "Feijão": "Frijoles",
        "Soja": "Soja",
        "Milho": "Maíz",
        "Algodão": "Algodón",
        "Amendoim": "Maní",
        "Cana-de-açúcar": "Caña de Azúcar",
        "Trigo": "Trigo",
        "Outro": "Otro",
        "Teste de germinação": "Prueba de Germinación",
        "Pequena": "Pequeña",
        "Nenhum gráfico foi gerado!": "¡No se ha generado ningún gráfico!",
        'Preencha os campos acima e clique em "Gerar gráfico" para aparecer aqui.': 'Complete los campos anteriores y haga clic en "Generar gráfico" para verlo aquí.',
        "Gerar gráfico": "Generar gráfico",
        "Experimentos finalizados": "Experimentos finalizados",
        "Sistemas": "Sistemas",
        "Cultura": "Cultivo",
        "Data de início dos scans": "Fecha de inicio de escaneos",
        "Setor/Bancada": "Sector/Mesa",
        "Data de semeadura": "Fecha de siembra",
        "Número de tratamentos": "Número de tratamientos",
        "Número de scans": "Número de escaneos",
        "Número de repetições": "Número de repeticiones",
        "Horário dos scans": "Horario de escaneos",
        "Data de fim de experimento": "Fecha de fin de experimento",
        "Avaliação do sistema": "Evaluación del sistema",
        "Código da bandeja": "Código de la bandeja",
        "Tratamentos": "Tratamientos",
        "Período do gráfico": "Período del gráfico",
        "Variável observada": "Variable observada",
        "Selecione o código da bandeja": "Seleccione el código de la bandeja",
        "Selecione a variável observada": "Seleccione la variable observada",
        "Selecione o tratamento": "Seleccione el tratamiento",
        "Não encontramos nenhum gráfico para sua pesquisa!": "¡No encontramos ningún gráfico para su búsqueda!",
        "Considere mudar os dados da pesquisa e tente novamente.": "Considere cambiar los datos de la búsqueda e intente nuevamente.",
        "Informações do gráfico": "Información del gráfico",
        "às": "a las",
        "Selecionar tudo": "Seleccionar todo",
        "Selecionar datas dos scans": "Seleccionar fechas de los escaneos",
        "Individual": "Individual",
        "Agrupamento": "Agrupamiento",
        "Selecione os horários de scan": "Seleccione los horarios de escaneo",
        "Nenhum gráfico gerado!": "¡No se generó ningún gráfico!",
        "Preencha todas as informações acima para gerar o gráfico.": "Complete toda la información anterior para generar el gráfico.",
        "Selecione o sistema": "Seleccione el sistema",
        "Gerenciar grupos": "Gestionar grupos",
        "Códigos": "Códigos",
        "Grupos": "Grupos",
        "Nome do grupo": "Nombre del grupo",
        "Criar grupo": "Crear grupo",
        "Comparar grupos": "Comparar grupos",
        "Digite o nome do grupo": "Escriba el nombre del grupo",
        "Código": "Código",
        "Grupo": "Grupo",
        "Média": "Promedio",
        "Área folicular": "Área foliar",
        "Nenhum grupo selecionado": "Ningún grupo seleccionado",
        "Selecionar grupos": "Seleccionar grupos",
        "Selecione as datas dos scans": "Seleccione las fechas de los escaneos"
    }
}
