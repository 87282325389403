import { useEffect, useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import Swal from 'sweetalert2';

import { AuthProvider, AuthContext } from 'contexts/auth';
import { MenuProvider } from 'contexts/menu';
import LoginPage from 'pages/LoginPage';
import HomePage from 'pages/HomePage';
import ReportsPage from 'pages/ReportsPage';
import GalleryPage from 'pages/GalleryPage';
import NotFoundPage from 'pages/NotFoundPage';
import SystemsPage from 'pages/SystemsPage';
import Experiment from 'pages/Experiment';
import Settings from 'pages/Settings';
import { useTranslation } from 'react-i18next';
import DashboardsPage from 'pages/DashboardsPage';

const AppRoutes = () => {
  const { t } = useTranslation()

  const Private = ({ admin, children }) => {
    const { isLoading, isAuthenticated, user } = useContext(AuthContext);

    if (isLoading) {
      Swal.fire({
        title: 'Carregando...',
        allowOutsideClick: false,
        showCloseButton: false,
        didOpen: async () => {
          Swal.showLoading();
        }
      });
      return null;
    } else {
      Swal.close();
    }

    if (admin && user?.role !== 'admin') {
      return (
        <Navigate to="*" />
      )
    }

    if (!isAuthenticated) {
      return (
        <Navigate to="/login" />
      )
    }

    return children;
  }


  const Page = ({ title, children }) => {
    useEffect(() => {
      document.title = title;
    }, [title]);

    return children;
  }

  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/login" element={
            <Page title={t("Faça seu login")}>
              <LoginPage />
            </Page>
          } />

          {/* PRIVATE ROUTES */}
          <Route path="/" element={
            <Private>
              <Page title={t("Página inicial")}>
                <MenuProvider>
                  <HomePage />
                </MenuProvider>
              </Page>
            </Private>
          } />
          <Route path="/reports" element={
            <Private>
              <Page title={t("Relatórios")}>
                <MenuProvider>
                  <ReportsPage />
                </MenuProvider>
              </Page>
            </Private>
          } />
          <Route path="/gallery" element={
            <Private>
              <Page title={t("Galeria")}>
                <MenuProvider>
                  <GalleryPage />
                </MenuProvider>
              </Page>
            </Private>
          } />
          <Route path="/dashboards" element={
            <Private>
              <Page title={t("Painéis")}>
                <MenuProvider>
                  <DashboardsPage />
                </MenuProvider>
              </Page>
            </Private>
          } />

          <Route path="/systems" element={
            <Private admin>
              <Page title={t("Sistemas")}>
                <MenuProvider>
                  <SystemsPage />
                </MenuProvider>
              </Page>
            </Private>
          } />

          <Route path="/settings" element={
            <Private>
              <Page title={t("Configurações")}>
                <MenuProvider>
                  <Settings />
                </MenuProvider>
              </Page>
            </Private>
          } />

          <Route path="/experiment/new" element={
            <Private>
              <Page title={t("Novo experimento")}>
                <MenuProvider>
                  <Experiment />
                </MenuProvider>
              </Page>
            </Private>
          } />

          <Route path="/experiment/:experiment_uuid" element={
            <Private>
              <Page title={t("Editar experimento")}>
                <MenuProvider>
                  <Experiment />
                </MenuProvider>
              </Page>
            </Private>
          } />

          {/* 👇️ only match this when no other routes match */}
          <Route path="*" element={
            <Page title={t("Página não encontrada")}>
              <NotFoundPage />
            </Page>
          } />
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default AppRoutes;