'use client'
import React, { useEffect, useRef, useState } from 'react'
import { Table, Form } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './styles.scss'
import Text from '../Text'
import { RiArrowDropLeftLine, RiArrowDropRightLine, RiFilter2Line, RiSearch2Line } from 'react-icons/ri'
import { FaEllipsisH } from 'react-icons/fa'
import { getRootVar } from 'utils/generalFunctions'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal'

const DataTable = ({
  data,
  columns,
  filters = true,
  isLoading = false,
  noDataMessage,
  exportData,
  secondIndex = false,
  itemsPerPage = 15,
  sortDate,
  selectedSeries,
  group,
  onRowClick
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [showFilters, setShowFilters] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const [exportLoading, setExportLoading] = useState('')
  const [filterModal, setFilterModal] = useState(false)

  const { t } = useTranslation()
  const exportRef = useRef(null)

  const parseDateTime = (scan_date, scan_time) => {
    if (group) {
      const [day, month, year] = scan_date.split('/').map(Number);
      const [hour, minute] = scan_time.split(':').map(Number);
      return new Date(year, month - 1, day, hour, minute);
    }
  };

  const sortedGroups = group && data.sort((a, b) => {
    const dateA = parseDateTime(a.scan_date, a.scan_time);
    const dateB = parseDateTime(b.scan_date, b.scan_time);
    return dateA - dateB;
  })

  const sortedData =
    !group && sortDate
      ? secondIndex
        ? data
          .sort((a, b) => {
            const dateA = new Date(a[1]?.[sortDate]?.split('/').reverse().join('-') || 0);
            const dateB = new Date(b[1]?.[sortDate]?.split('/').reverse().join('-') || 0);

            if (dateA - dateB !== 0) return dateA - dateB;

            return a[1]?.code.localeCompare(b[1]?.code);
          })
          .map(item => item[1])
        : data.sort((a, b) => {
          const dateA = new Date(a?.[sortDate]?.split('/').reverse().join('-') || 0);
          const dateB = new Date(b?.[sortDate]?.split('/').reverse().join('-') || 0);

          if (dateA - dateB !== 0) return dateA - dateB;

          return a.code.localeCompare(b.code);
        })
      : secondIndex
        ? data
          .sort((a, b) =>
            new Date(b[1]?.start_date || 0) - new Date(a[1]?.start_date || 0)
          )
          .map(item => item[1])
        : data.sort((a, b) =>
          new Date(b.start_date || 0) - new Date(a.start_date || 0)
        );

  const dataType = group ? sortedGroups : sortedData

  const filteredData = dataType.filter(item =>
    columns.some(column =>
      column?.key &&
      item[column.key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage)

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        exportRef.current &&
        !exportRef.current.contains(event.target)
      ) {
        setShowExport(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [exportRef])

  useEffect(() => {
    setCurrentPage(1)
  }, [totalPages])

  const theme = document.querySelector("body").getAttribute("data-theme");

  return (
    data && (
      <>
        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }} className={exportLoading ? 'export-loading' : ''} >
          {window.innerWidth > 980 ?
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-wrap">
                <div xs="12" md="auto" className="d-flex gap-2 mb-md-0 align-items-center">
                  <Form.Group className="mb-0 position-relative">
                    <div className="input-icon">
                      <RiSearch2Line size={14} color={getRootVar("--gray3")} />
                    </div>
                    <Form.Control
                      type="text"
                      placeholder={t('Pesquisar')}
                      value={searchTerm}
                      onChange={handleSearch}
                      className="input-custom input-color"
                    />
                  </Form.Group>
                  {filters &&
                    <button
                      className={`d-flex filter-icon background-table ${showFilters ? 'enable' : 'disable'}`}
                      onClick={() => setShowFilters(!showFilters)}
                    >
                      <RiFilter2Line
                        size={16}
                        color={showFilters ? "white" : getRootVar("--gray3")}
                      />
                    </button>}
                </div>
                <div xs="12" md="auto" className="d-flex">
                  {showFilters && filters}
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-end align-items-center">
                <div className="d-flex align-items-center">
                  <Text className="smallTxt gray-2" autoTranslate={false}>
                    {totalPages === 0 ? "0 - " : `${(currentPage - 1) * itemsPerPage + 1} - `}
                    {Math.min(currentPage * itemsPerPage, filteredData.length)}
                  </Text>
                  &nbsp;
                  <Text className="smallTxt gray-2">de</Text>
                  &nbsp;
                  <Text className="smallTxt gray-2" autoTranslate={false}>
                    {filteredData.length}
                  </Text>
                </div>
                <div className="d-flex align-items-center">
                  <RiArrowDropLeftLine
                    onClick={() => currentPage === 1 || totalPages === 0 ? '' : handlePageChange(currentPage - 1)}
                    color={`${currentPage === 1 || totalPages === 0
                      ? theme === "dark"
                        ? getRootVar("--gray4")
                        : getRootVar("--altWhite")
                      : getRootVar("--gray3")}`}
                    size={30}
                    style={{ cursor: currentPage === 1 || totalPages === 0 ? 'default' : 'pointer' }}
                  />
                  <RiArrowDropRightLine
                    onClick={() => currentPage === totalPages || totalPages === 0 ? '' : handlePageChange(currentPage + 1)}
                    color={`${currentPage === totalPages || totalPages === 0
                      ? theme === "dark"
                        ? getRootVar("--gray4")
                        : getRootVar("--altWhite")
                      : getRootVar("--gray3")}`}
                    size={30}
                    style={{ cursor: currentPage === totalPages || totalPages === 0 ? 'default' : 'pointer' }}
                  />
                </div>
                {exportData &&
                  <div className="d-flex position-relative filter-button background-table">
                    <FaEllipsisH
                      size={16}
                      className="gray-3"
                      onClick={() => setShowExport(!showExport)}
                    />
                  </div>}
              </div>
            </div> :
            <div className="d-flex flex-column justify-content-between">
              <div className="d-flex gap-3 align-items-center">
                <Form.Group className="mb-0 position-relative" style={{ width: '100%' }}>
                  <div className="input-icon">
                    <RiSearch2Line size={18} color={getRootVar("--gray3")} />
                  </div>
                  <Form.Control
                    type="text"
                    placeholder={t('Pesquisar')}
                    value={searchTerm}
                    onChange={handleSearch}
                    className="input-custom input-color"
                  />
                </Form.Group>
                {filters &&
                  <button
                    className={`d-flex filter-icon background-table ${showFilters ? 'enable' : 'disable'}`}
                    onClick={() => setFilterModal(true)}
                  >
                    <RiFilter2Line
                      size={16}
                      color={showFilters ? "white" : getRootVar("--gray3")}
                    />
                  </button>}
              </div>
              <div className="d-flex justify-content-end" style={{ width: "100%", marginTop: "16px" }}>
                <div className="d-flex align-items-center">
                  <Text className="smallTxt gray-2" autoTranslate={false}>
                    {totalPages === 0 ? "0 - " : `${(currentPage - 1) * itemsPerPage + 1} - `}
                    {Math.min(currentPage * itemsPerPage, filteredData.length)}
                  </Text>
                  &nbsp;
                  <Text className="smallTxt gray-2">de</Text>
                  &nbsp;
                  <Text className="smallTxt gray-2" autoTranslate={false}>
                    {filteredData.length}
                  </Text>
                </div>
                <div className="d-flex align-items-center">
                  <RiArrowDropLeftLine
                    onClick={() => currentPage === 1 || totalPages === 0 ? '' : handlePageChange(currentPage - 1)}
                    color={`${currentPage === 1 || totalPages === 0
                      ? theme === "dark"
                        ? getRootVar("--gray4")
                        : getRootVar("--altWhite")
                      : getRootVar("--gray3")}`}
                    size={30}
                    style={{ cursor: currentPage === 1 || totalPages === 0 ? 'default' : 'pointer' }}
                  />
                  <RiArrowDropRightLine
                    onClick={() => currentPage === totalPages || totalPages === 0 ? '' : handlePageChange(currentPage + 1)}
                    color={`${currentPage === totalPages || totalPages === 0
                      ? theme === "dark"
                        ? getRootVar("--gray4")
                        : getRootVar("--altWhite")
                      : getRootVar("--gray3")}`}
                    size={30}
                    style={{ cursor: currentPage === totalPages || totalPages === 0 ? 'default' : 'pointer' }}
                  />
                </div>
                {exportData &&
                  <div className="d-flex position-relative filter-button background-table">
                    <FaEllipsisH
                      size={16}
                      className="gray-3"
                      onClick={() => setShowExport(!showExport)}
                    />
                  </div>}
              </div>
            </div>
          }
          {data?.length > 0 ?
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr className="background-table">
                    {isLoading
                      ? columns.map((_, index) => (
                        <th key={index}>
                          <Skeleton baseColor={getRootVar("--altWhite")} highlightColor={getRootVar("--gray4")} className="skeleton" />
                        </th>
                      ))
                      : columns.map((column, index) =>
                        column && (
                          index === columns.length - 1 ? (
                            <th className="last-index" key={index}>
                              {column.name}
                            </th>
                          ) : (
                            <th key={index}>{column.name}</th>
                          )
                        )
                      )}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`table-row-hover ${group ? selectedSeries === item?.group_name ? 'selected' : '' : selectedSeries === item?.code ? 'selected' : ''}`}
                        onClick={() => onRowClick(item)}
                      >
                        {columns.map((column, colIndex) =>
                          column && (
                            colIndex === columns.length - 1 ? (
                              <td className='last-index' key={colIndex}>{column.render(item)}</td>
                            ) : (
                              <td key={colIndex}>{column.render(item)}</td>
                            )
                          )
                        )}
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
            : noDataMessage}
        </div >
        {filterModal &&
          <Modal
            title="Filtrar tabela"
            handleCloseModal={() => setFilterModal(false)}
            scroll={false}>
            {filters}
          </Modal>
        }
      </>
    )
  )
}

export default DataTable
