import {
  useState,
  useEffect,
} from 'react';
import {
  parse,
  format,
  isBefore,
  isAfter,
} from 'date-fns';
import { useContext } from 'react';
import { cloneDeep } from "lodash";
import { MenuContext } from 'contexts/menu';
import {
  listExperiments,
  listExperimentTrays,
  getGalleryItems,
} from 'services/api';
import './index.scss';
import Text from 'components/Text';
import { Select } from 'components/Select';
import Button from 'components/Button';
import DatePicker from 'components/Date';
import { RiArrowDropLeftLine, RiCheckboxLine, RiDownload2Line, RiFolder3Line, RiSearch2Line } from 'react-icons/ri';
import { HiDotsHorizontal } from "react-icons/hi";
import { getRootVar } from 'utils/generalFunctions';
import { useTranslation } from 'react-i18next';
import JSZip from "jszip";
import { saveAs } from "file-saver";
import SliderModal from 'components/Slider';
import { Tooltip } from 'react-tooltip';

const imageTypes = [
  {
    label: "Raw",
    value: "raw"
  },
  {
    label: "Mask",
    value: "mask"
  },
  {
    label: "Tray",
    value: "tray"
  }]

const GalleryPage = () => {
  const { selectedCompany, selectedGreenhouse } = useContext(MenuContext);
  const [galleryItems, setGalleryItems] = useState();
  const [selectedAlbum, setSelectedAlbum] = useState();
  const [selectedRadioOfImageType, setSelectedRadioOfImageType] = useState(imageTypes[0]);
  const [isLightboxOpened, setIsLightboxOpened] = useState(false);
  const [experiments, setExperiments] = useState([]);
  const [experiment, setExperiment] = useState("");
  const [searchPhotos, setSearchPhotos] = useState("");
  const [loadingAlbum, setLoadingAlbum] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectImages, setSelectImages] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isAllImagesSelected, setIsAllImagesSelected] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isLoadingExperiments, setIsLoadingExperiments] = useState(false);
  const [groupedExperimentOptions, setGroupedExperimentOptions] = useState([]);
  const { t } = useTranslation()

  const getThreeMonthsAgo = () => {
    const today = new Date();
    const threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    return threeMonthsAgo;
  };

  const initialRange = [getThreeMonthsAgo(), new Date()];
  const [rangeDate, setRangeDate] = useState(initialRange);

  useEffect(() => {
    if (rangeDate?.length > 1) {
      async function updateExperiments() {
        const activeData = {};
        const inactiveData = {};

        try {
          const activeRes = await listExperiments(
            selectedCompany?.name,
            selectedGreenhouse?.name,
            true
          );

          for (const exp of activeRes.data) {
            const expStartDate = exp.exp_start_date && parse(exp.exp_start_date, 'yyyyMMdd', new Date());
            const expEndDate = exp.end_date && parse(exp.end_date, 'yyyyMMdd', new Date());

            if (
              (expStartDate && isAfter(expStartDate, rangeDate[1])) ||
              (expEndDate && isBefore(expEndDate, rangeDate[0]))
            ) {
              continue;
            }

            const item = {
              ...exp,
              start_date: expStartDate,
              end_date: expEndDate,
              formatted_start_date: format(expStartDate, 'dd/MM/yyyy'),
            };
            activeData[exp.uuid] = item;
          }

          const activeExperimentsArray = Object.values(activeData);

          const activeExperimentsListObj = activeExperimentsArray.sort((a, b) =>
            new Date(b.start_date) - new Date(a.start_date)
          ).map(e => ({
            label: e.name,
            value: e.uuid
          }));

          const inactiveRes = await listExperiments(
            selectedCompany?.name,
            selectedGreenhouse?.name,
            false,
            rangeDate[0],
            rangeDate[1]
          );

          for (const exp of inactiveRes.data) {
            const expStartDate = exp.exp_start_date && parse(exp.exp_start_date, 'yyyyMMdd', new Date());
            const expEndDate = exp.end_date && parse(exp.end_date, 'yyyyMMdd', new Date());

            if (
              (expStartDate && isAfter(expStartDate, rangeDate[1])) ||
              (expEndDate && isBefore(expEndDate, rangeDate[0]))
            ) {
              continue;
            }

            const item = {
              ...exp,
              start_date: expStartDate,
              end_date: expEndDate,
              formatted_start_date: format(expStartDate, 'dd/MM/yyyy'),
            };
            inactiveData[exp.uuid] = item;
          }

          const inactiveExperimentsArray = Object.values(inactiveData);

          const inactiveExperimentsListObj = inactiveExperimentsArray.sort((a, b) =>
            new Date(b.start_date) - new Date(a.start_date)
          ).map(e => ({
            label: e.name,
            value: e.uuid
          }));

          const groupedOptions = [
            {
              label: t('Experimentos ativos'),
              options: activeExperimentsListObj,
            },
            {
              label: t('Experimentos finalizados'),
              options: inactiveExperimentsListObj,
            }
          ]

          setGroupedExperimentOptions(groupedOptions)
          setIsLoadingExperiments(false)
        } catch (ex) {
          console.error('ex:', ex);
        }
      }

      if (selectedCompany && selectedGreenhouse) {
        updateExperiments();
      }
    }
  }, [selectedCompany, selectedGreenhouse, rangeDate]);

  async function handleSearchPhotos() {
    try {
      const res = await listExperimentTrays(selectedCompany?.name, experiment?.value)

      const data = res.data.map(i => {
        return {
          key: i.code ? i.code : `${i.position.x}_${i.position.y}`,
          key_type: i.code ? "tray_code" : "tray_position",
        };
      }).filter((item, index, array) => {
        return index === array.findIndex(obj => obj.key === item.key);
      }).sort((a, b) => a.key.localeCompare(b.key));
      setGalleryItems(data);
      setSelectedAlbum(null);
      setSearchPhotos(true)
    } catch (ex) {
      console.error('ex:', ex);
      return;
    }
  }

  async function handleAlbumClick(targetItem) {
    setLoadingAlbum(true)

    const matchingGalleryItem = galleryItems.find(item => item.key === targetItem.key && item.items);
    if (matchingGalleryItem) {
      setSelectedAlbum(matchingGalleryItem);
      return;
    }

    const includePreviousExperiments = true;
    const res = await getGalleryItems(
      selectedCompany.name,
      selectedGreenhouse.name,
      experiment.value,
      targetItem.key,
      targetItem.key_type,
      includePreviousExperiments,
    );
    const data = res.data.data;
    const lastItem = cloneDeep(data[data.length - 1]);
    let selectedItem = targetItem;

    const items = galleryItems.map(item => {
      if (item.key === targetItem.key) {
        item = {
          key: item.key,
          items: data.map(j => {
            j.key = `${item.key} / ${j.timestamp}`;
            return j;
          }),
          ...lastItem,
        };
        selectedItem = item;
      }
      return item;
    });

    setGalleryItems(items);
    setSelectedAlbum(selectedItem);
    setLoadingAlbum(false)
  }

  async function handlePictureClick(index) {
    setIsLightboxOpened(true);
    setSelectedImageIndex(index);
  }

  const onChange = (value, setValue) => {
    setValue(value)
  }

  function formatDate(dateString) {
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    const [date, time] = dateString.split(' ');
    const [day, month, year] = date.split('/');

    if (selectedLanguage === "en-US") {
      return `${month}/${day}/${year} at ${time}`;
    } else {
      return `${day}/${month}/${year} às ${time}`;
    }
  }

  function goBackToFolders() {
    setSelectedAlbum(null)
    setLoadingAlbum(false)
    setSelectImages(null)
    setSelectedImages([])
    setIsAllImagesSelected(false)
  }

  function handleImageOptions(id) {
    setSelectedCardId(prevId => (prevId === id ? null : id));
  }

  function handleDownloadImage(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "image.jpg");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async function handleDownloadSelectedImages() {
    const zip = new JSZip();

    const promises = selectedImages.map(async (image) => {
      const response = await fetch(image.url);
      const blob = await response.blob();
      const date = image.DTime.replace(/\//g, ".")
      zip.file(`${date}.jpg`, blob);
    });

    await Promise.all(promises);

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${selectedAlbum.key}.zip`);
    });
  }

  function handleSelectImage(item) {
    setSelectImages(true)
    setSelectedCardId(null)
    setSelectedImages((prevSelected) =>
      prevSelected?.includes(item)
        ? prevSelected.filter((selectedId) => selectedId.gcode_uuid !== item.gcode_uuid)
        : [...prevSelected, item]
    );
  };

  function handleCancelSelectImages(id) {
    setSelectImages(false)
    setSelectedImages([])
    setIsAllImagesSelected(false)
  };

  const loadingElements = [];
  for (let i = 0; i < 8; i++) {
    loadingElements.push(
      <div className="LoadingImg" key={i} />
    );
  }

  function handleChangeCheckbox(item) {
    setSelectedImages((prevSelected) =>
      prevSelected?.includes(item)
        ? prevSelected.filter((selectedId) => selectedId.gcode_uuid !== item.gcode_uuid)
        : [...prevSelected, item]
    );
  };

  function handleSelectAllImages(images) {
    setIsAllImagesSelected(state => !state)
    if (isAllImagesSelected) {
      setSelectedImages([])
    } else {
      setSelectedImages(images)
    }
  };

  const selectedNumber = selectedImages?.length ? selectedImages.length : 0
  const imagesList = selectedAlbum?.items.length

  return (
    <>
      <div className="Experiment">
        <div className="Header">
          <div className="PageNav">
            <Text className="bold big gray2">Galeria de imagens</Text>
          </div>
          {window.innerWidth > 980 && (
            selectImages ? (
              <Button disabled={selectedNumber === 0} onClick={handleDownloadSelectedImages} className="primary">
                <RiDownload2Line size="16" color="white" />
                <Text className="bold white">{t('Baixar imagens ({{selectedNumber}})', { selectedNumber })}</Text>
              </Button>
            ) : <Button disabled={!rangeDate || !experiment} onClick={handleSearchPhotos} className="primary">
              <Text className="bold white">Consultar fotos</Text>
              <RiSearch2Line size={14} color={!rangeDate || !experiment ? getRootVar("--gray3") : "white"} />
            </Button>
          )}
        </div >
        <div className="Content">
          <div className="Group">
            <div className="Inputs">
              <DatePicker
                label={<Text className="bold smallTxt">Período do experimento</Text>}
                placeholder="Selecione o período do experimento"
                value={rangeDate}
                onChange={(date) => onChange(date, setRangeDate)}
                mode="range"
                required
              />
              <Select
                label={<Text className="bold smallTxt">Experimento</Text>}
                disabled={rangeDate?.length < 2}
                options={groupedExperimentOptions}
                value={experiment}
                placeholder="Selecione o experimento"
                onChange={(e) => onChange(e, setExperiment)}
                isLoading={isLoadingExperiments}
                required
              />
              <Select
                label={<Text className="bold smallTxt">Tipo de foto</Text>}
                options={imageTypes}
                value={selectedRadioOfImageType}
                placeholder="Selecione o tipo de foto"
                onChange={(e) => onChange(e, setSelectedRadioOfImageType)}
                clear={false}
              />
              {window.innerWidth < 980 && (
                selectImages ? (
                  <Button className="fluid primary" disabled={selectedNumber === 0} onClick={handleDownloadSelectedImages}>
                    <RiDownload2Line size="16" color="white" />
                    <Text className="bold white">{t('Baixar imagens ({{selectedNumber}})', { selectedNumber })}</Text>
                  </Button>
                ) : <Button className="fluid primary" disabled={!rangeDate || !experiment} onClick={handleSearchPhotos}>
                  <Text className="bold white">Consultar fotos</Text>
                  <RiSearch2Line size={14} color={!rangeDate || !experiment ? getRootVar("--gray3") : "white"} />
                </Button>
              )}
            </div>
          </div>
        </div>
        {searchPhotos && (
          <div className="Content">
            <div className="Container" style={{ gap: "24px", display: selectedAlbum && 'flex', flexDirection: selectedAlbum && "column" }}>
              {selectedAlbum?.items ?
                <>
                  {selectImages ? (
                    <div className='d-flex align-items-center justify-content-between'>
                      <div onClick={() => goBackToFolders()} className='d-flex align-items-center gap-1' style={{ cursor: "pointer", width: "fit-content" }}>
                        <RiArrowDropLeftLine size="25" color={getRootVar("--gray3")} />
                        <Text>{selectedAlbum?.key}</Text>
                      </div>
                      <div className="SelectOptions">
                        <Text onClick={() => handleCancelSelectImages()} className="smallTxt" color={getRootVar("--gray2")}>Cancelar</Text>
                        <Text className="smallTxt" onClick={() => handleSelectAllImages(selectedAlbum.items)} color={getRootVar("--secondary")}>{isAllImagesSelected ? t('Deselecionar todos ({{imagesList}})', { imagesList }) : t('Selecionar todos ({{imagesList}})', { imagesList })}</Text>
                      </div>
                    </div>
                  ) : <div onClick={() => goBackToFolders()} className='d-flex align-items-center gap-1' style={{ cursor: "pointer", width: "fit-content" }}>
                    <RiArrowDropLeftLine size="25" color={getRootVar("--gray3")} />
                    <Text>{selectedAlbum?.key}</Text>
                  </div>
                  }
                  <div className="Container">
                    {loadingAlbum ? loadingElements
                      : (selectedAlbum.items.map((item, index) => (
                        <div
                          key={item.key}
                          className="Thumbnail"
                        >
                          <div
                            className="Image"
                            onClick={() => handlePictureClick(index)}
                          >
                            <img
                              src={selectedRadioOfImageType.value === "tray" ? item.thumbnail_url : selectedRadioOfImageType.value === "mask" ? item.color_thumbnail_url : item.raw_url}
                              alt=" "
                            />
                          </div>
                          {selectedCardId === item.gcode_uuid &&
                            <div className='ImageOptions'>
                              <div className="Options" onClick={() => handleSelectImage(item)}>
                                <RiCheckboxLine size="16" color={getRootVar("--gray2")} />
                                <Text className="smallTxt">Selecionar</Text>
                              </div>
                              <div className="Options" onClick={() => handleDownloadImage(item.url)}>
                                <RiDownload2Line size="16" color={getRootVar("--gray2")} />
                                <Text className="smallTxt">Baixar</Text>
                              </div>
                            </div>}
                          <div className="Label">
                            <Text className="smallTxt">{formatDate(item.DTime)}</Text>
                            {selectImages ? (
                              <div className="Checkbox">
                                <input
                                  className="Checkbox"
                                  type="checkbox"
                                  onChange={() => handleChangeCheckbox(item)}
                                  checked={selectedImages.map(image => image.gcode_uuid).includes(item.gcode_uuid)}
                                />
                              </div>
                            ) :
                              <div key={item.gcode_uuid} style={{ cursor: "pointer" }} onClick={() => handleImageOptions(item.gcode_uuid)}>
                                <HiDotsHorizontal size="20" color={getRootVar("--gray2")} />
                              </div>
                            }
                          </div>
                        </div>
                      )))}
                  </div>
                </>
                : galleryItems && galleryItems.map(item => (
                  <>
                    <div className="Folder" data-tooltip-id={`folder_tooltip_${item.key}`} onClick={() => handleAlbumClick(item)}>
                      <RiFolder3Line size="16" color={getRootVar("--gray2")} />
                      <Text>{item.key}</Text>
                    </div>
                    {item.key.length > 15 &&
                      <Tooltip
                        id={`folder_tooltip_${item.key}`}
                        style={{ backgroundColor: getRootVar("white"), border: "1px solid red", boxShadow: getRootVar("shadow") }}>
                        <div className='d-flex align-items-start flex-column'>
                          <Text className="smallTxt">{item.key}</Text>
                        </div>
                      </Tooltip>}
                  </>
                ))
              }
            </div>
          </div>
        )}
      </div>
      {isLightboxOpened && (
        <SliderModal
          initialIndex={selectedImageIndex}
          onClose={() => setIsLightboxOpened(false)}
          items={selectedAlbum?.items}
          selectedRadioOfImageType={selectedRadioOfImageType}
          setSelectedRadioOfImageType={setSelectedRadioOfImageType}
          onChange={onChange}
          handleDownloadImage={handleDownloadImage}
          formatDate={formatDate} />
      )}
    </>
  )
}

export default GalleryPage