import React from 'react';
import ReactECharts from 'echarts-for-react';

const Charts = ({
    options,
    loading,
    changeTheme
}) => {

    const theme = changeTheme ? changeTheme : document.querySelector("body").getAttribute("data-theme");

    return (
        <div style={{
            borderRadius: "16px",
            overflow: "hidden",
            backgroundColor: theme === "light" ? "#f9f9f9" : "#101010",
            padding: "12px 8px"
        }}>
            <ReactECharts
                option={options}
                notMerge={true}
                lazyUpdate={true}
                showLoading={loading}
                theme={theme}
            />
        </div>
    )
}

export default Charts
