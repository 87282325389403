import React from 'react'
import './styles.scss';

export const Button = ({
  className,
  isLoading,
  children,
  disabled,
  icon,
  width,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={`btn ${icon ? "btnIcon" : "btnContainer"} ${className}`}
      disabled={disabled || isLoading}
      style={{
        width: width,
        border: 'none',
        boxShadow: 'none'
      }}
    >
      {isLoading ? (
        <div className="Spinner" />
      ) : children}
    </button>
  )
}

export default Button
