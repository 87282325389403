import {
  useState,
  useEffect,
} from 'react';
import {
  parse,
  format,
  isBefore,
  isAfter,
} from 'date-fns';
import { useContext } from 'react';
import { MenuContext } from 'contexts/menu';
import ReactLoading from 'react-loading';
import {
  listExperiments,
  listExperimentTrays,
  getUrlOfExperimentResultsV2,
  handleSelectOptions,
  listGreenhouses,
  getExperiment,
  getExperimentGcodes,
} from 'services/api';
import './index.scss';
import Text from 'components/Text';
import { Select } from 'components/Select';
import Button from 'components/Button';
import DatePicker from 'components/Date';
import { RiSearch2Line } from 'react-icons/ri';
import { formatDate, getRootVar, onChange } from 'utils/generalFunctions';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/Table';
import { toast } from 'react-toastify';
import Modal from 'components/Modal';
import { AuthContext } from 'contexts/auth';
import Charts from 'components/Chart';
import CodeGroupsDashboard from 'components/CodeGroupsDashboard';

const colorPalette = [
  '#5470C6',
  '#91CC75',
  '#FAC858',
  '#EE6666',
  '#73C0DE',
  '#3BA272',
  '#FC8452',
  '#9A60B4',
  '#EA7CCC'
];

const DashboardsPage = () => {
  const { t } = useTranslation();

  const analisisType = [
    {
      label: t("Individual"),
      value: "single"
    },
    {
      label: t("Agrupamento"),
      value: "group"
    },
  ]

  const { selectedCompany, selectedGreenhouse } = useContext(MenuContext);
  const [selectedAnalisisType, setSelectedAnalisisType] = useState(analisisType[0]);
  const [experiment, setExperiment] = useState("");
  const [loadingContent, setLoadingContent] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [filteredChartData, setFilteredChartData] = useState(chartData);
  const [allowedScanTimes, setAllowedScanTimes] = useState(false);
  const [isContentGenerated, setIsContentGenerated] = useState(false);
  const [isChartGenerated, setIsChartGenerated] = useState(false);
  const [groupedExperimentOptions, setGroupedExperimentOptions] = useState([])
  const [isLoadingExperiments, setIsLoadingExperiments] = useState([])
  const [isLoadingGreenhouses, setIsLoadingGreenhouses] = useState([])
  const [greenhouses, setGreenhouses] = useState([])
  const [greenhousesOptions, setGreenhousesOptions] = useState([])
  const [greenhouse, setGreenhouse] = useState("")
  const [experimentInfo, setExperimentInfo] = useState([])
  const [treatmentNumber, setTreatmentNumber] = useState("")
  const [repetitionNumber, setRepetitionNumber] = useState("")
  const [uniqueScanTimes, setUniqueScanTimes] = useState("")
  const [trayCode, setTrayCode] = useState("")
  const [treatment, setTreatment] = useState("")
  const [scanTime, setScanTime] = useState("")
  const [trayCodeOptions, setTrayCodeOptions] = useState([])
  const [treatmentOptions, setTreatmentOptions] = useState([])
  const [scanTimeOptions, setScanTimeOptions] = useState([])
  const [chartOptions, setChartOptions] = useState("")
  const [greenhouseName, setGreenhouseName] = useState("")
  const [loadingChart, setLoadingChart] = useState(false)
  const [benchs, setBenchs] = useState([])
  const [scanStartDate, setScanStartDate] = useState("")
  const [experimentGcodes, setExperimentGcodes] = useState([])
  const [scansDates, setScansDates] = useState("")
  const [isLoadingScansDates, setIsLoadingScansDates] = useState(false)
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [columns, setColumns] = useState([]);
  const [chartSeries, setChartSeries] = useState(null);
  const [isGroupsModalOpen, setIsGroupsModalOpen] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupedResults, setGroupedResults] = useState([]);
  const [chartTheme, setChartTheme] = useState("");
  const [selectedColor, setSelectedColor] = useState("");

  const toggleTheme = () => {
    setChartTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const observedVariableList = selectedAnalisisType.value === "group" ? [
    {
      label: t("Número de plantas"),
      value: "plants_number"
    },
    {
      label: t("Cobertura foliar"),
      value: "folicular_cover"
    }
  ] : [
    {
      label: t("Número de plantas"),
      value: "plants_number"
    },
    {
      label: t("Cobertura foliar"),
      value: "folicular_cover"
    },
    {
      label: t("GLI"),
      value: "gli"
    }
  ]

  const [observedVariable, setObservedVariable] = useState(observedVariableList[0])

  const { user } = useContext(AuthContext);

  const getColorForListItems = (e) => {
    if (e) {
      if (chartSeries) {
        const series = chartSeries?.find((s) => s.name === e)
        const selectedColor = colorPalette[chartSeries.indexOf(series) % colorPalette.length]

        return selectedColor
      }

      if (selectedGroups) {
        const groups = selectedGroups.find((group) => group.name === e)
        const selectedColor = colorPalette[selectedGroups.indexOf(groups) % colorPalette.length]

        return selectedColor
      }
    }
  };

  const makeColumns = () => {
    if (selectedAnalisisType.value === "single") {
      return setColumns(
        [
          {
            key: 'code',
            name: <Text className="gray-2 f-2 bold">Código</Text>,
            render: (entity,) => (
              <div className='d-flex align-items-center gap-2'>
                <div className="codeColor" style={{
                  backgroundColor: getColorForListItems(entity.code)
                }} />
                <Text className="gray-2 f-2" autoTranslate={false}>
                  {entity.code ? entity.code : '-'}
                </Text>
              </div>
            )
          },
          {
            key: 'scan_date',
            name: <Text className="gray-2 f-2 bold">Data do scan</Text>,
            render: (entity) => (
              <Text className="gray-2 f-2" autoTranslate={false}>
                {entity.scan_date ? formatDate(entity.scan_date) : '-'}
              </Text>
            )
          },
          {
            key: 'scan_time',
            name: <Text className="gray-2 f-2 bold">Hora do scan</Text>,
            render: (entity) => (
              <Text className="gray-2 f-2" autoTranslate={false}>
                {entity.scan_time ? entity.scan_time : '-'}
              </Text>
            )
          },
          observedVariable.value === "plants_number" &&
          {
            key: 'plants_number',
            name: <Text className="gray-2 f-2 bold">Número de plantas</Text>,
            render: (entity) => (
              <Text className="gray-2 f-2" autoTranslate={false}>
                {entity.plants_number ? entity.plants_number : '0'}
              </Text>
            )
          },
          observedVariable.value === "folicular_cover" &&
          {
            key: 'full_leaf_coverage',
            name: <Text className="gray-2 f-2 bold">Área folicular</Text>,
            render: (entity) => (
              <Text className="gray-2 f-2" autoTranslate={false}>
                {entity.full_leaf_coverage ? entity.full_leaf_coverage : '-'}
              </Text>
            )
          },
          observedVariable.value === "gli" &&
          {
            key: 'gli_mean',
            name: <Text className="gray-2 f-2 bold">Média</Text>,
            render: (entity) => (
              <Text className="gray-2 f-2" autoTranslate={false} style={{ maxWidth: "100px" }}>
                {entity.gli_mean ? entity.gli_mean : '-'}
              </Text>
            )
          },
          observedVariable.value === "gli" &&
          {
            key: 'gli_std',
            name: <Text className="gray-2 f-2 bold">Desvio padrão</Text>,
            render: (entity) => (
              <Text className="gray-2 f-2" autoTranslate={false}>
                {entity.gli_std ? entity.gli_std : '-'}
              </Text>
            )
          },
          observedVariable.value === "gli" &&
          {
            key: 'gli_var',
            name: <Text className="gray-2 f-2 bold">Variança</Text>,
            render: (entity) => (
              <Text className="gray-2 f-2" autoTranslate={false}>
                {entity.gli_var ? entity.gli_var : '-'}
              </Text>
            )
          },
        ])
    } else {
      return setColumns(
        [
          {
            key: 'group_name',
            name: <Text className="gray-2 f-2 bold">Grupo</Text>,
            render: (entity) => {
              return (
                <div className='d-flex align-items-center gap-2'>
                  <div className="codeColor" style={{
                    backgroundColor: getColorForListItems(entity.group_name)
                  }} />
                  <Text className="gray-2 f-2" autoTranslate={false}>
                    {entity.group_name ? entity.group_name : '-'}
                  </Text>
                </div>
              )

            }
          },
          {
            key: 'scan_date',
            name: <Text className="gray-2 f-2 bold">Data do scan</Text>,
            render: (entity) => {
              return (
                <Text className="gray-2 f-2" autoTranslate={false}>
                  {entity.scan_date ? formatDate(entity.scan_date) : '-'}
                </Text>
              )
            }
          },
          {
            key: 'scan_time',
            name: <Text className="gray-2 f-2 bold">Hora do scan</Text>,
            render: (entity) => (
              <Text className="gray-2 f-2" autoTranslate={false}>
                {entity.scan_time ? entity.scan_time : '-'}
              </Text>
            )
          },
          observedVariable.value === "plants_number" &&
          {
            key: 'average_plants_number',
            name: <Text className="gray-2 f-2 bold">Número de plantas</Text>,
            render: (entity) => (
              <Text className="gray-2 f-2" autoTranslate={false}>
                {entity.average_plants_number ? entity.average_plants_number : '0'}
              </Text>
            )
          },
          observedVariable.value === "folicular_cover" &&
          {
            key: 'average_folicular_cover',
            name: <Text className="gray-2 f-2 bold">Área folicular</Text>,
            render: (entity) => (
              <Text className="gray-2 f-2" autoTranslate={false}>
                {entity.average_folicular_cover ? entity.average_folicular_cover : '-'}
              </Text>
            )
          },
        ])
    }
  }

  const getThreeMonthsAgo = () => {
    const today = new Date();
    const threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    return threeMonthsAgo;
  };

  const initialRange = [getThreeMonthsAgo(), new Date()];
  const [rangeDate, setRangeDate] = useState(initialRange);

  function formatSpecificDate(dateString) {
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    if (dateString) {
      const day = dateString.slice(6, 8)
      const month = dateString.slice(4, 6)
      const year = dateString.slice(0, 4)

      if (selectedLanguage === "en-US") {
        return `${month}/${day}/${year}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    }
  }

  useEffect(() => {
    if (rangeDate?.length > 1) {
      setIsLoadingExperiments(true)

      async function updateExperiments() {
        const activeData = {};
        const inactiveData = {};

        try {
          const activeRes = await listExperiments(
            selectedCompany?.name,
            selectedGreenhouse?.name,
            true
          );

          for (const exp of activeRes.data) {
            const expStartDate = exp.exp_start_date && parse(exp.exp_start_date, 'yyyyMMdd', new Date());
            const expEndDate = exp.end_date && parse(exp.end_date, 'yyyyMMdd', new Date());

            if (
              (expStartDate && isAfter(expStartDate, rangeDate[1])) ||
              (expEndDate && isBefore(expEndDate, rangeDate[0]))
            ) {
              continue;
            }

            const item = {
              ...exp,
              start_date: expStartDate,
              end_date: expEndDate,
              formatted_start_date: format(expStartDate, 'dd/MM/yyyy'),
            };
            activeData[exp.uuid] = item;
          }

          const activeExperimentsArray = Object.values(activeData);

          const activeExperimentsListObj = activeExperimentsArray.sort((a, b) =>
            new Date(b.start_date) - new Date(a.start_date)
          ).map(e => ({
            label: e.name,
            value: e.uuid
          }));

          const inactiveRes = await listExperiments(
            selectedCompany?.name,
            selectedGreenhouse?.name,
            false,
            rangeDate[0],
            rangeDate[1]
          );

          for (const exp of inactiveRes.data) {
            const expStartDate = exp.exp_start_date && parse(exp.exp_start_date, 'yyyyMMdd', new Date());
            const expEndDate = exp.end_date && parse(exp.end_date, 'yyyyMMdd', new Date());

            if (
              (expStartDate && isAfter(expStartDate, rangeDate[1])) ||
              (expEndDate && isBefore(expEndDate, rangeDate[0]))
            ) {
              continue;
            }

            const item = {
              ...exp,
              start_date: expStartDate,
              end_date: expEndDate,
              formatted_start_date: format(expStartDate, 'dd/MM/yyyy'),
            };
            inactiveData[exp.uuid] = item;
          }

          const inactiveExperimentsArray = Object.values(inactiveData);

          const inactiveExperimentsListObj = inactiveExperimentsArray.sort((a, b) =>
            new Date(b.start_date) - new Date(a.start_date)
          ).map(e => ({
            label: e.name,
            value: e.uuid
          }));

          const groupedOptions = [
            {
              label: t('Experimentos ativos'),
              options: activeExperimentsListObj,
            },
            {
              label: t('Experimentos finalizados'),
              options: inactiveExperimentsListObj,
            }
          ]

          setGroupedExperimentOptions(groupedOptions)
          setIsLoadingExperiments(false)
        } catch (ex) {
          console.error('ex:', ex);
        }
      }
      if (selectedCompany && selectedGreenhouse) {
        updateExperiments();
      }
    }

    async function selectOptions() {
      const allowedScanTimeOptions = await handleSelectOptions(selectedCompany?.name, selectedGreenhouse?.name, 'allowed_scan_times');

      setAllowedScanTimes(allowedScanTimeOptions)
    }

    selectOptions()

  }, [selectedCompany, selectedGreenhouse, rangeDate]);

  useEffect(() => {
    const updateGreenhouses = async (companyName) => {
      setIsLoadingGreenhouses(true);

      const items = [];
      try {
        const res = await listGreenhouses(companyName);
        for (const greenhouse of res.data) {
          items.push({
            name: greenhouse.name,
            geometry: greenhouse.geometry_map,
            benches_geometry: greenhouse.benches_geometry_map,
            trays_of_last_scan_geometry: greenhouse.trays_of_last_scan_geometry_map,
            config: greenhouse.config,
          });
        }
      } catch (ex) {
        console.error('updateGreenhouses ex:', ex);
        return;
      }
      if (items.length > 0) {
        items.sort((a, b) => a.name.localeCompare(b.name));

        if (user.role === 'employee' || items.length === 1) {
          const greenhouse = {
            label: items[0].name,
            value: items[0].name
          }

          setGreenhouse(greenhouse);
        }

        setGreenhouses(items);

        const greenhousesOptions = items.map((item) => ({
          label: item.name,
          value: item.name
        }))

        setGreenhousesOptions(greenhousesOptions)
      }

      setIsLoadingGreenhouses(false);
    }

    if (selectedCompany) {
      updateGreenhouses(selectedCompany.name);
    }
  }, [
    selectedCompany,
    setGreenhouse,
    setGreenhouses,
    setIsLoadingGreenhouses,
  ]);

  async function handleGenerateContent() {
    setLoadingContent(true);
    setIsChartGenerated(false)
    setIsContentGenerated(true)
    setTrayCode("")
    setTreatment("")
    setScanTime("")
    setScansDates("")
    setSelectedGroups("")
    setSelectedSeries(null)
    setFilteredChartData("")
    setGroupedResults("")

    try {
      const res = await getExperiment(selectedCompany?.name, selectedGreenhouse?.name, experiment?.value,)
      setExperimentInfo(res.data.data)

    } catch (ex) {
      console.error('ex:', ex);
      toast.error("Nenhum dado encontrado")
    }

    try {
      const res = await listExperimentTrays(selectedCompany?.name, experiment?.value)
      const noRepeatBench = [...new Set(res.data.map(item => item.bench))]
      setBenchs(noRepeatBench)
    } catch (ex) {
      console.error("ex:", ex);
    }

    try {
      const res = await getExperimentGcodes(
        greenhouse?.value,
        experiment?.value,
        selectedCompany?.name,
        true,
        0,
        false,
        0,
        0,
        [
          "Bancada 01",
          "Bancada 02",
          "Bancada 03",
          "Bancada 04"
        ],
        "not_started"
      )
      setExperimentGcodes(res)
    } catch (ex) {
      console.error("ex:", ex);
    }

    try {
      const res = await getUrlOfExperimentResultsV2(
        selectedCompany?.name,
        selectedGreenhouse?.name,
        experiment?.value,
        "long",
        "tray",
        true,
        true,
        true,
        false,
        true
      );

      const scanStartDate = res.data.data[0].scan_date
      setScanStartDate(scanStartDate)

      const treatmentNumber = res.data.data.filter((item) => item?.treatment !== "?")
      setTreatmentNumber(treatmentNumber.length > 0 ? treatmentNumber.length : 0)

      const repetitionNumber = res.data.data.filter((item) => item?.repetition !== "?")
      setRepetitionNumber(repetitionNumber.length > 0 ? repetitionNumber.length : 0)

      const uniqueScanTimes = [...new Set(res.data.data.map(item => item.scan_time))];

      const groupByHour = (timeStr) => {
        if (timeStr) {
          const [hours] = timeStr.split(':');
          return `${hours}:00`;
        }
        return null;
      };

      const groupedTimes = [...new Set(uniqueScanTimes.map(groupByHour).filter(Boolean))];

      const sortedGroupedTimes = groupedTimes.length > 1
        ? groupedTimes.sort((a, b) => {
          const [hoursA] = a.split(':').map(Number);
          const [hoursB] = b.split(':').map(Number);
          return hoursA - hoursB;
        })
        : [groupedTimes[0]]

      setUniqueScanTimes(sortedGroupedTimes);

      const trayOptions = [...new Set(res.data.data.map(item => item.code))].map(item => ({
        label: item,
        value: item
      }))

      setTrayCodeOptions(trayOptions)

      const treatmentsOptions = [...new Set(res.data.data.map(item => item.treatment))].map(item => ({
        label: item,
        value: item
      }))

      setTreatmentOptions(treatmentsOptions)

      const scanTimeOptions = [...new Set(sortedGroupedTimes.map(item => ({
        label: item,
        value: item
      })))]

      setScanTimeOptions(scanTimeOptions)
      setGreenhouseName(selectedGreenhouse?.name)
      setChartData(res.data.data)
      setLoadingContent(false);
    } catch (ex) {
      console.error('ex:', ex);
      setLoadingContent(false);
      return;
    }
  }

  const handleChangeAnalisisType = (value, setValue) => {
    if (value.value === "group") {
      setTrayCode(null)
      setScansDates(null)

      if (observedVariable.value === "gli") {
        setObservedVariable(observedVariableList[0])
      }
    }
    setSelectedSeries(null)
    setFilteredChartData("")
    setIsChartGenerated(false)
    setChartSeries("")
    setValue(value);
  };

  function transformArrayToText(array) {
    if (Array.isArray(array)) {
      return array.join(", ");
    } else {
      return array
    }
  }

  function capitalizeFirstLetter(str) {
    const translatedStr = t(str)
    return translatedStr.charAt(0).toUpperCase() + translatedStr.slice(1);
  };

  function getTimestamp(scan_date, scan_time) {
    // Extrai dia, mês e ano do scan_date
    const [day, month, year] = scan_date.split('/');
    // Extrai hora e minuto do scan_time
    const [hour, minute] = scan_time.split(':');

    // Cria um novo objeto Date com os valores de data e hora
    const dateObj = new Date(year, month - 1, day, hour, minute);
    // Retorna o timestamp em milissegundos
    return dateObj.getTime();
  }

  function handleSelectedGroups() {
    setIsGroupsModalOpen(false)
    handleGenerateGroupChart()
  }

  async function handleGenerateSingleChart() {
    const isReadyToGenerateChart =
      selectedAnalisisType &&
      observedVariable &&
      trayCode.length > 0 &&
      scanTime.length > 0;

    if (isReadyToGenerateChart && chartData) {
      let filteredData = chartData;

      if (trayCode.length > 0) {
        filteredData = filteredData.filter((item) =>
          trayCode.some((code) => code.value === item.code)
        );
      }

      if (scanTime.length > 0) {
        filteredData = filteredData.filter((item) =>
          scanTime.some((scan) => scan.value === item.scan_time)
        );
      }

      if (!scansDates) {
        setIsLoadingScansDates(true);
        const dataScansDate = [...new Set(filteredData.map((item) => item.scan_date))];

        const formatScansDates = (date) => {
          if (date) {
            const [day, month, year] = date.split('/').map(Number);
            return new Date(year, month - 1, day);
          }
        };

        const initialScansDates = dataScansDate.map((item) => formatScansDates(item));

        setScansDates(initialScansDates);
        setIsLoadingScansDates(false);
      } else {
        setIsChartGenerated(true);

        if (trayCode.length > 0 && scanTime.length > 0) {
          setLoadingChart(true);

          const formatScanDate = (date) => {
            return date.toLocaleDateString('pt-BR');
          };

          filteredData = filteredData.filter((item) => {
            const convertedScansDates = scansDates?.map((date) => formatScanDate(date));
            return convertedScansDates.some((date) => date === item.scan_date);
          });

          if (observedVariable.value === "plants_number") {
            const plantsNumber = Object.values(
              filteredData.reduce((acc, item) => {
                const timestamp = getTimestamp(item.scan_date, item.scan_time);
                if (!acc[item.code]) {
                  acc[item.code] = {
                    name: item.code,
                    type: "line",
                    data: [],
                  };
                }
                acc[item.code].data.push([timestamp, item.plants_number]);
                return acc;
              }, {})
            );

            if (JSON.stringify(plantsNumber) !== JSON.stringify(chartSeries)) {
              setChartSeries(plantsNumber);
            }
          } else if (observedVariable.value === "folicular_cover") {
            const folicularCover = Object.values(
              filteredData.reduce((acc, item) => {
                const timestamp = getTimestamp(item.scan_date, item.scan_time);
                if (!acc[item.code]) {
                  acc[item.code] = {
                    name: item.code,
                    type: "line",
                    data: [],
                  };
                }
                acc[item.code].data.push([timestamp, item.full_leaf_coverage]);
                return acc;
              }, {})
            );

            if (JSON.stringify(folicularCover) !== JSON.stringify(chartSeries)) {
              setChartSeries(folicularCover);
            }
          } else {
            const gli = Object.values(
              filteredData.reduce((acc, item) => {
                const timestamp = getTimestamp(item.scan_date, item.scan_time);

                if (!acc[item.code]) {
                  acc[item.code] = {
                    name: item.code,
                    type: "line",
                    data: [],
                  };
                }

                acc[item.code].data.push([
                  timestamp,
                  item.gli_mean,
                  item.gli_std,
                  item.gli_var,
                ]);

                return acc;
              }, {})
            );

            if (JSON.stringify(gli) !== JSON.stringify(chartSeries)) {
              setChartSeries(gli);
            }
          }

          const iconPath = chartTheme === "dark" ? "m6.76 4.84-1.8-1.79-1.41 1.41 1.79 1.79zM1 10.5h3v2H1zM11 .55h2V3.5h-2zm8.04 2.495 1.408 1.407-1.79 1.79-1.407-1.408zm-1.8 15.115 1.79 1.8 1.41-1.41-1.8-1.79zM20 10.5h3v2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm-1 4h2v2.95h-2zm-7.45-.96 1.41 1.41 1.79-1.8-1.41-1.41z" : "M10 7C10 10.866 13.134 14 17 14C18.9584 14 20.729 13.1957 21.9995 11.8995C22 11.933 22 11.9665 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C12.0335 2 12.067 2 12.1005 2.00049C10.8043 3.27098 10 5.04157 10 7ZM4 12C4 16.4183 7.58172 20 12 20C15.0583 20 17.7158 18.2839 19.062 15.7621C18.3945 15.9187 17.7035 16 17 16C12.0294 16 8 11.9706 8 7C8 6.29648 8.08133 5.60547 8.2379 4.938C5.71611 6.28423 4 8.9417 4 12Z"

          const chartOptions = {
            title: {
              text: experimentInfo?.name,
              textStyle: { fontSize: window.innerWidth > 960 ? 16 : 14 },
            },
            backgroundColor: chartTheme === "light" ? "#f9f9f9" : "#101010",
            tooltip: window.innerWidth > 960 && trayCode.length <= 10
              ? {
                backgroundColor: getRootVar("--altWhite"),
                borderColor: getRootVar("--altWhite"),
                trigger: "axis",
                axisPointer: { type: "shadow" },
                formatter: (params) => {
                  const date = new Date(params[0].value[0]);
                  const formattedDate = `${formatDate(date)} ${t("às")} ${date.getHours().toString().padStart(2, '0')}:${date
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`;

                  const filteredParams = selectedSeries
                    ? params.filter(item => item.seriesName === selectedSeries)
                    : params;

                  const tooltipContent = filteredParams.map((item, index) => {
                    return observedVariable.value === "gli"
                      ? `
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${selectedSeries ? selectedColor : colorPalette[index % colorPalette.length]};"></span>
                <span style="color:${getRootVar("--gray1")};">${item.seriesName}:</span>
                <b style="color:${getRootVar("--gray1")};">${t("Mean")}: ${item.value[1]}</b>
                <b style="color:${getRootVar("--gray1")};">${t("Std")}: ${item.value[2]}</b>
                <b style="color:${getRootVar("--gray1")};">${t("Var")}: ${item.value[3]}</b>
              `
                      : `
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${selectedSeries ? selectedColor : colorPalette[index % colorPalette.length]};"></span>
                <span style="color:${getRootVar("--gray1")};">${item.seriesName}:</span>
                <b style="color:${getRootVar("--gray1")};">${item.value[1]}</b>
              `;
                  }).join('<br/>');

                  return `<span style="color:${getRootVar("--gray1")};">${formattedDate}</span><br/>${tooltipContent}`;
                },
              }
              : false,

            grid: {
              left: window.innerWidth > 960 ? "6%" : "11%",
              right: "4%",
              bottom: "5%",
              containLabel: true,
            },
            toolbox: {
              feature: {
                saveAsImage: {},
                myCustomTool: {
                  show: true,
                  title: t("Trocar Tema"),
                  icon: `path://${iconPath}`,
                  onclick: toggleTheme,
                },
              },
            },
            xAxis: {
              type: "time",
              boundaryGap: true,
              axisLabel: {
                rotate: window.innerWidth > 960 ? 0 : 45,
              }
            },
            yAxis: {
              name: observedVariable?.label,
              type: "value",
            },
            series: chartSeries?.map((series, index) => ({
              ...series,
              itemStyle: {
                color: selectedSeries === series.name
                  ? colorPalette[index % colorPalette.length]
                  : `${colorPalette[index % colorPalette.length]}80`,
                opacity: selectedSeries ? (selectedSeries === series.name ? 1 : 0) : 1,
              },
              lineStyle: {
                color: colorPalette[index % colorPalette.length],
                opacity: selectedSeries ? (selectedSeries === series.name ? 1 : 0) : 1,
                width: selectedSeries === series.name ? 4 : 2,
              },
            })),
            dataZoom: [{ type: "inside", start: 0, end: 100 }],
          };

          makeColumns()
          setFilteredChartData(filteredData);
          setChartOptions(chartOptions);
          setLoadingChart(false);
        }
      }
    } else {
      setIsChartGenerated(false);
    }
  }

  function handleGenerateGroupChart() {
    if (!selectedGroups || selectedGroups.length === 0 || scanTime?.length === 0) {
      setIsChartGenerated(false);
      return;
    }

    let filteredData = chartData;

    const groupedResult = selectedGroups.map((group) => {
      filteredData = chartData.filter((obj) => group.value.includes(obj.code));

      if (scanTime.length > 0) {
        filteredData = filteredData.filter((item) =>
          scanTime.some((scan) => scan.value === item.scan_time)
        );
      }

      if (!scansDates) {
        setIsLoadingScansDates(true);
        const dataScansDate = [
          ...new Set(filteredData.map((item) => item.scan_date)),
        ];

        const formatScansDates = (date) => {
          if (date) {
            const [day, month, year] = date.split("/").map(Number);
            return new Date(year, month - 1, day);
          }
        };

        const initialScansDates = dataScansDate.map((item) =>
          formatScansDates(item)
        );

        setScansDates(initialScansDates);
        setIsLoadingScansDates(false);
      } else {
        const formatScanDate = (date) => {
          return date.toLocaleDateString('pt-BR');
        };

        filteredData = filteredData.filter((item) => {
          const convertedScansDates = scansDates?.map((date) => formatScanDate(date));
          return convertedScansDates.some((date) => date === item.scan_date);
        });
      }

      const dataByDayAndTime = filteredData.reduce((acc, obj) => {
        const scanDate = obj.scan_date;
        const scanTime = obj.scan_time;

        if (!acc[scanDate]) {
          acc[scanDate] = {};
        }
        if (!acc[scanDate][scanTime]) {
          acc[scanDate][scanTime] = [];
        }
        acc[scanDate][scanTime].push(
          observedVariable.value === "plants_number"
            ? obj.plants_number
            : obj.full_leaf_coverage
        );
        return acc;
      }, {});

      const data = Object.entries(dataByDayAndTime).flatMap(([scanDate, times]) => {
        const [day, month, year] = scanDate.split("/").map(Number);
        const dateTimestamp = new Date(year, month - 1, day).getTime();

        return Object.entries(times).map(([scanTime, values]) => {
          const total = values.reduce((sum, val) => sum + val, 0);
          const average = total / values.length;

          const [hours, minutes] = scanTime.split(":").map(Number);
          const timestamp =
            dateTimestamp + hours * 60 * 60 * 1000 + minutes * 60 * 1000;

          return [timestamp, average];
        });
      });

      data.sort((a, b) => a[0] - b[0]);

      return {
        name: group.name,
        type: "line",
        data,
      };
    });

    const calculateAverageByGroup = () => {
      return selectedGroups.map(group => {
        const groupedData = {};
        filteredData.forEach(item => {
          const key = `${item.scan_date}-${item.scan_time}`;
          if (!groupedData[key]) {
            groupedData[key] = { total: 0, count: 0 };
          }

          const analysisValue =
            observedVariable.value === "plants_number"
              ? item.plants_number
              : item.full_leaf_coverage;

          groupedData[key].total += analysisValue;
          groupedData[key].count += 1;
        });

        const averages = Object.entries(groupedData).map(([key, value]) => {
          const [scan_date, scan_time] = key.split('-');

          return {
            scan_date,
            scan_time,
            ...(observedVariable.value === "plants_number"
              ? { average_plants_number: value.total / value.count }
              : { average_folicular_cover: value.total / value.count }),
          };
        });

        return {
          group_name: group.name,
          data: averages,
        };
      });
    };

    const groupedTableResult = calculateAverageByGroup();

    const flattenedData = groupedTableResult.flatMap(group =>
      group.data.map(item => ({
        ...item,
        group_name: group.group_name
      }))
    );

    let filteredTableData = flattenedData
    const filtered = flattenedData.filter(group => {
      if (scanTime?.length > 0) {
        filteredTableData = filteredTableData.filter((item) =>
          scanTime.some((scan) => scan.value === item.scan_time)
        );
      }

      if (scansDates?.length > 0) {
        filteredTableData = filteredTableData
          .filter(item => scansDates?.some(date => {
            const formattedDate = date.toLocaleDateString('pt-BR');
            return formattedDate === item.scan_date;
          }));
      }

      return filteredTableData
    })

    setGroupedResults(groupedResult);

    const iconPath = chartTheme === "dark" ? "m6.76 4.84-1.8-1.79-1.41 1.41 1.79 1.79zM1 10.5h3v2H1zM11 .55h2V3.5h-2zm8.04 2.495 1.408 1.407-1.79 1.79-1.407-1.408zm-1.8 15.115 1.79 1.8 1.41-1.41-1.8-1.79zM20 10.5h3v2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm-1 4h2v2.95h-2zm-7.45-.96 1.41 1.41 1.79-1.8-1.41-1.41z" : "M10 7C10 10.866 13.134 14 17 14C18.9584 14 20.729 13.1957 21.9995 11.8995C22 11.933 22 11.9665 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C12.0335 2 12.067 2 12.1005 2.00049C10.8043 3.27098 10 5.04157 10 7ZM4 12C4 16.4183 7.58172 20 12 20C15.0583 20 17.7158 18.2839 19.062 15.7621C18.3945 15.9187 17.7035 16 17 16C12.0294 16 8 11.9706 8 7C8 6.29648 8.08133 5.60547 8.2379 4.938C5.71611 6.28423 4 8.9417 4 12Z"

    const chartOptions = {
      title: {
        text: experimentInfo?.name,
        textStyle: { fontSize: 16 },
      },
      backgroundColor: chartTheme === "light" ? "#f9f9f9" : "#101010",
      tooltip: window.innerWidth > 960 && selectedGroups?.length <= 10
        ? {
          backgroundColor: getRootVar("--altWhite"),
          borderColor: getRootVar("--altWhite"),
          trigger: "axis",
          axisPointer: { type: "shadow" },
          formatter: (params) => {
            const date = new Date(params[0].value[0]);
            const formattedDate = `${formatDate(date)} ${t("às")} ${date.getHours().toString().padStart(2, '0')}:${date
              .getMinutes()
              .toString()
              .padStart(2, '0')}`;

            const filteredParams = selectedSeries
              ? params.filter(item => item.seriesName === selectedSeries)
              : params;

            const tooltipContent = filteredParams
              .map((item, index) => `
            <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${selectedSeries ? selectedColor : colorPalette[index % colorPalette.length]
                };"></span>
            <span style="color:${getRootVar("--gray1")};">${item.seriesName}:</span>
            <b style="color:${getRootVar("--gray1")};">${item.value[1].toFixed(2)}</b>
          `)
              .join('<br/>');

            return `<span style="color:${getRootVar("--gray1")};">${formattedDate}</span><br/>${tooltipContent}`;
          },
        }
        : false,

      grid: {
        left: window.innerWidth > 960 ? "6%" : "11%",
        right: "4%",
        bottom: "5%",
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {},
          myCustomTool: {
            show: true,
            title: t("Trocar Tema"),
            icon: `path://${iconPath}`,
            onclick: toggleTheme,
          },
        }
      },
      xAxis: {
        type: "time",
        boundaryGap: true,
        axisLabel: {
          rotate: window.innerWidth > 960 ? 0 : 45,
        }
      },
      yAxis: { name: observedVariable?.label, type: "value" },
      series: groupedResult?.map((series, index) => ({
        ...series,
        itemStyle: {
          color:
            selectedSeries === series.name
              ? colorPalette[index % colorPalette.length]
              : `${colorPalette[index % colorPalette.length]}80`,
          opacity: selectedSeries
            ? selectedSeries === series.name
              ? 1
              : 0
            : 1,
        },
        lineStyle: {
          color: colorPalette[index % colorPalette.length],
          opacity: selectedSeries
            ? selectedSeries === series.name
              ? 1
              : 0
            : 1,
          width: selectedSeries === series.name ? 4 : 2,
        },
      })),
      dataZoom: [{ type: "inside", start: 0, end: 100 }],
    };

    makeColumns()
    setFilteredChartData(filtered);
    setChartOptions(chartOptions);
    setIsChartGenerated(true);
  }


  function handleSelectAllOptions(options, setValue) {
    setValue(options)
  }

  useEffect(() => {
    if (!observedVariable || !scanTime || !selectedAnalisisType) {
      return;
    }

    if (selectedAnalisisType?.value === "single") {
      handleGenerateSingleChart();
    } else if (selectedGroups?.length > 0) {
      handleGenerateGroupChart();
    }
  }, [observedVariable, trayCode, scanTime, selectedAnalisisType, scansDates, selectedSeries, chartSeries, chartTheme]);


  const handleRowClick = (item) => {
    if (item.group_name) {
      const groups = groupedResults.find((group) => group.name === item.group_name);
      const selectedColor = colorPalette[groupedResults.indexOf(groups) % colorPalette.length]

      setSelectedColor(selectedColor)
      if (selectedSeries === item.group_name) {
        setSelectedSeries(null)
      } else {
        setSelectedSeries(item.group_name)
      }
    } else {
      const series = chartSeries.find((s) => s.name === item.code)
      const selectedColor = colorPalette[chartSeries.indexOf(series) % colorPalette.length]

      setSelectedColor(selectedColor)
      if (selectedSeries === item.code) {
        setSelectedSeries(null)
      } else {
        setSelectedSeries(item.code)
      }
    }
  };

  function handleClickGroupsModal() {
    setIsGroupsModalOpen(state => !state)
  }

  function saveGroupsList(list) {
    setGroupList(list)
  }

  function handleState(value) {
    setSelectedGroups(value)
  }

  return (
    <>
      <div className="DashboardsPage">
        <div className="Header">
          <div className="PageNav">
            <Text className="bold big gray2">Painéis</Text>
          </div>
          {window.innerWidth > 980 && (
            <div className="d-flex align-items-center gap-3">
              <Button disabled={!rangeDate || !experiment} onClick={handleGenerateContent} className="primary">
                <Text className="bold white">Gerar gráfico</Text>
              </Button>
            </div>
          )}
        </div >
        <div className="Content">
          <div className="Group">
            <div className="Inputs">
              <Select
                label={<Text className="bold smallTxt">Sistemas</Text>}
                options={greenhousesOptions}
                value={greenhouse}
                placeholder="Selecione o sistema"
                onChange={(e) => onChange(e, setGreenhouse)}
                disabled={user.role === 'admin' ? false : true}
                isLoading={isLoadingGreenhouses}
              />
              <DatePicker
                label={<Text className="bold smallTxt">Período do experimento</Text>}
                placeholder="Selecione o período do experimento"
                value={rangeDate}
                onChange={(date) => onChange(date, setRangeDate)}
                mode="range"
              />
              <Select
                label={<Text className="bold smallTxt">Experimento</Text>}
                disabled={!rangeDate}
                options={groupedExperimentOptions}
                value={experiment}
                placeholder="Selecione o experimento"
                onChange={(e) => onChange(e, setExperiment)}
                isLoading={isLoadingExperiments}
              />
              {window.innerWidth < 980 && (
                <div className="d-flex align-items-center flex-column gap-3" style={{ width: "100%" }}>
                  <Button disabled={!rangeDate || !experiment || !greenhouse} onClick={handleGenerateContent} className="primary fluid">
                    <Text className="bold white">Gerar gráfico</Text>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {isContentGenerated && !loadingContent ? (
          <>
            <div className="Content">
              <div className='d-flex flex-column gap-3 '>
                <Text className="bold secondaryTxt">Avaliação do sistema</Text>
                <div className="dashboardInfos">
                  <div className="dashGroups">
                    <div className="dashItem">
                      <Text className="tinyTxt">Empresa</Text>
                      <Text className="bold">{selectedCompany ? selectedCompany.name : "-"}</Text>
                    </div>
                    <div className="dashItem">
                      <Text className="tinyTxt">Nome do experimento</Text>
                      <Text className="bold">{experimentInfo?.name ? experimentInfo.name : "-"}</Text>
                    </div>
                    <div className="dashItem">
                      <Text className="tinyTxt">Setor/Bancada</Text>
                      <Text className="bold">{benchs ? transformArrayToText(benchs) : "-"}</Text>
                    </div>
                  </div>
                  <div className="dashGroups">
                    <div className="dashItem">
                      <Text className="tinyTxt">Data de semeadura</Text>
                      <Text className="bold">{experimentInfo?.exp_start_date ? formatSpecificDate(experimentInfo.exp_start_date) : "-"}</Text>
                    </div>
                    <div className="dashItem">
                      <Text className="tinyTxt">Data de fim de experimento</Text>
                      <Text className="bold">{experimentInfo.end_date ? formatSpecificDate(experimentInfo.end_date) : "-"}</Text>
                    </div>
                    <div className="dashItem">
                      <Text className="tinyTxt">Cultura</Text>
                      <Text className="bold">{experimentInfo?.crop ? capitalizeFirstLetter(experimentInfo.crop) : "-"}</Text>
                    </div>
                  </div>
                  <div className="dashGroups">
                    <div className="dashItem">
                      <Text className="tinyTxt">Número de tratamentos</Text>
                      <Text className="bold">{treatmentNumber?.length > 0 ? treatmentNumber : "-"}</Text>
                    </div>
                    <div className="dashItem">
                      <Text className="tinyTxt">Número de repetições</Text>
                      <Text className="bold">{repetitionNumber?.length > 0 ? repetitionNumber : "-"}</Text>
                    </div>
                    <div className="dashItem">
                      <Text className="tinyTxt">Número de scans</Text>
                      <Text className="bold">-</Text>
                    </div>
                  </div>
                  <div className="dashGroups">
                    <div className="dashItem">
                      <Text className="tinyTxt">Data de início dos scans</Text>
                      <Text className="bold">{scanStartDate ? formatDate(scanStartDate) : "-"}</Text>
                    </div>
                    <div className="dashItem">
                      <Text className="tinyTxt">Horário dos scans</Text>
                      <Text autoTranslate={false} className="bold">{uniqueScanTimes ? transformArrayToText(uniqueScanTimes) : "-"}</Text>
                    </div>
                    <div className="dashItem">
                      <Text className="tinyTxt">Tipo de experimento</Text>
                      <Text className="bold">{experimentInfo?.exp_type ? capitalizeFirstLetter(experimentInfo.exp_type) : "-"}</Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Content">
              <div className="Group">
                <Text className="bold secondaryTxt">Informações do gráfico</Text>
                <div className="Inputs">
                  <Select
                    label={<Text className="bold smallTxt">Método de análise</Text>}
                    options={analisisType}
                    value={selectedAnalisisType}
                    onChange={(e) => handleChangeAnalisisType(e, setSelectedAnalisisType)}
                  />
                  <Select
                    label={<Text className="bold smallTxt">Variável observada</Text>}
                    options={observedVariableList}
                    value={observedVariable}
                    placeholder="Selecione a variável observada"
                    onChange={(e) => onChange(e, setObservedVariable)}
                    isLoading={isLoadingExperiments}
                  />
                  {selectedAnalisisType.value !== "group" &&
                    <Select
                      label={<Text className="bold smallTxt">Código da bandeja</Text>}
                      options={trayCodeOptions}
                      value={trayCode}
                      placeholder="Selecione o código da bandeja"
                      onChange={(e) => onChange(e, setTrayCode)}
                      isLoading={isLoadingExperiments}
                      action={() => handleSelectAllOptions(trayCodeOptions, setTrayCode)}
                      isMulti
                      closeMenuOnSelect={false}
                    />
                  }
                  <Select
                    label={<Text className="bold smallTxt">Horários de varredura</Text>}
                    options={scanTimeOptions}
                    value={scanTime}
                    placeholder="Selecione os horários de scan"
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={(e) => onChange(e, setScanTime)}
                  />
                  {window.innerWidth > 960 ? (
                    selectedAnalisisType.value === "group" &&
                    <div className="d-flex align-items-end flex-column gap-2" style={{ width: "100%", filter: scanTime?.length === 0 && "opacity(0.4)" }}>
                      {groupedResults?.length > 0 &&
                        <Text className="smallTxt bold secondaryTxt" onClick={() => scanTime?.length > 0 && setIsGroupsModalOpen(true)}>Gerenciar grupos
                        </Text>
                      }
                      <div className="groupsContainer">
                        {groupedResults?.length > 0 ?
                          <div className={`groupsList ${groupedResults.length > 5 ? "overflow" : ""}`}>
                            {groupedResults.map(group => {
                              return (
                                <div className="group" style={{ width: groupedResults.length > 5 ? "132.5px" : "137.5px" }}>
                                  <Text className="smallTxt">{group.name
                                  }</Text>
                                </div>
                              )
                            })}
                          </div>
                          : <div className='d-flex align-items-center justify-content-between' style={{ width: "100%" }}>
                            <Text className="smallTxt">Nenhum grupo selecionado</Text>
                            <Text className="smallTxt bold secondaryTxt" onClick={() => scanTime?.length > 0 && setIsGroupsModalOpen(true)}>Selecionar grupos</Text>
                          </div>
                        }
                      </div>
                    </div>
                  ) : (
                    selectedAnalisisType.value === "group" &&
                    <div className='d-flex align-items-center justify-content-between' style={{ width: "100%" }}>
                      <Button width="100%" className="secondary" disabled={scanTime?.length === 0} onClick={() => scanTime?.length > 0 && setIsGroupsModalOpen(true)}>
                        <Text className="smallTxt bold white">Selecionar grupos</Text>
                      </Button>
                    </div>
                  )}
                  <DatePicker
                    label={<Text className="bold smallTxt">Selecionar datas dos scans</Text>}
                    placeholder="Selecione as datas dos scans"
                    disabled={!scanTime || (selectedAnalisisType.value === "single" ? trayCode?.length === 0 : selectedGroups?.length === 0)}
                    value={scansDates}
                    onChange={(date) => onChange(date, setScansDates)}
                    mode="multiple"
                    loading={isLoadingScansDates}
                    minDate={formatSpecificDate(experimentInfo.exp_start_date)}
                    maxDate={formatSpecificDate(experimentInfo.end_date)}
                  />
                </div>
              </div>
            </div>
            {isChartGenerated ?
              filteredChartData?.length > 0 && (chartSeries[0]?.data || groupedResults?.length > 0) ?
                <>
                  <Charts loading={loadingChart} options={chartOptions} changeTheme={chartTheme} />
                  <DataTable
                    data={filteredChartData}
                    group={selectedAnalisisType.value === "group" ? true : false}
                    noDataMessage={
                      <div className="noExperimentData">
                        <RiSearch2Line size="60" color={getRootVar("--gray2")} />
                        <div className='noExperimentMessage'>
                          <Text className="bold">Nenhuma informação encontrada!</Text>
                          <Text className="smallTxt">Considere analisar os filtros e tente novamente.</Text>
                        </div>
                      </div>
                    }
                    columns={columns}
                    filters={false}
                    sortDate="scan_date"
                    selectedSeries={selectedSeries}
                    onRowClick={handleRowClick}
                  />
                </>
                :
                <div className="noTableData" style={{ marginTop: "0px" }}>
                  <div className='noDataMessage' style={{ width: !loadingChart && "100%" }}>
                    {loadingChart ? (
                      <ReactLoading
                        className="ReactLoading"
                        type={"spin"}
                        color={getRootVar("--gray3")}
                        height={25}
                        width={25}
                      />
                    ) : (
                      <>
                        <Text className="bold">Não encontramos nenhum gráfico para sua pesquisa!</Text>
                        <Text className="smallTxt">Considere mudar os dados da pesquisa e tente novamente.</Text>
                      </>
                    )}
                  </div>
                </div>
              :
              <div className="noTableData" style={{ marginTop: "0px" }}>
                <div className='noDataMessage' style={{ width: "100%" }}>
                  <Text className="bold">Nenhum gráfico gerado!</Text>
                  <Text className="smallTxt">Preencha todas as informações acima para gerar o gráfico.</Text>
                </div>
              </div>
            }
          </>
        ) :
          <div className="noTableData" style={{ marginTop: "0px" }}>
            <div className='noDataMessage' style={{ width: !loadingContent && "100%" }}>
              {loadingContent ? (
                <ReactLoading
                  className="ReactLoading"
                  type={"spin"}
                  color={getRootVar("--gray3")}
                  height={25}
                  width={25}
                />
              ) : (
                <>
                  <Text className="bold">Nenhum gráfico foi gerado!</Text>
                  <Text className="smallTxt">Preencha os campos acima e clique em "Gerar gráfico" para aparecer aqui.</Text>
                </>
              )}
            </div>
          </div>}
        {isGroupsModalOpen &&
          <Modal fluid={window.innerWidth > 960 ? true : false} full={window.innerWidth > 960 ? false : true} title="Gerenciar grupos"
            handleCloseModal={() => handleClickGroupsModal()}>
            <div className="modalReports">
              <CodeGroupsDashboard items={trayCodeOptions} handleSelectedGroups={handleSelectedGroups} saveGroupsList={saveGroupsList} handleState={handleState} savedGroupList={groupList} />
            </div>
          </Modal >
        }
      </div >
    </>
  );
};

export default DashboardsPage;
