import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import './styles.scss';
import Input from 'components/Input';
import Text from 'components/Text';
import { RiArrowLeftSLine } from "react-icons/ri";
import Button from 'components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from 'components/Select';
import { Textarea } from 'components/Textarea';
import DatePicker from 'components/Date';
import Modal from 'components/Modal';
import { MenuContext } from 'contexts/menu';
import { addDays, format, isBefore, parse, subWeeks } from 'date-fns';
import { toast } from 'react-toastify';
import { getExperiment, listExperiments, createExperiment, handleSelectOptions, updateExperiment } from 'services/api';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Experiment = () => {
  const [experimentType, setExperimentType] = useState("")
  const [farmingType, setFarmingType] = useState("")
  const [unitySize, setUnitySize] = useState("")
  const [scanTime, setScanTime] = useState("")

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [plantingDate, setPlantingDate] = useState("")

  const [experimentName, setExperimentName] = useState("")
  const [description, setDescription] = useState("")

  const [selectedActiveExperiment, setSelectedActiveExperiment] = useState(null);
  const [inactiveExperiments, setInactiveExperiments] = useState(null);
  const [inactiveExperimentsList, setInactiveExperimentsList] = useState(null);
  const [unitySizes, setUnitySizes] = useState([]);
  const [experimentTypes, setExperimentTypes] = useState([]);
  const [cropTypes, setCropTypes] = useState([]);
  const [allowedScanTimes, setAllowedScanTimes] = useState([]);
  const [experimentTime, setExperimentTime] = useState("");

  const [confirmLeavePageModal, setConfirmLeavePageModal] = useState(false)
  const [isEditabled, setIsEditabled] = useState(false)

  const navigate = useNavigate()
  const { selectedCompany, selectedGreenhouse } = useContext(MenuContext);
  const { experiment_uuid } = useParams()

  const selectedTrayLayers = useSelector(state => state.experiment.selectedTrayLayers);
  const { t } = useTranslation()

  const onChange = (value, setValue) => {
    setValue(value)
  }

  const onChangeRefExperiment = (refExperimentValue) => {
    setSelectedActiveExperiment(refExperimentValue)

    getExperiment(selectedCompany?.name, selectedGreenhouse?.name, refExperimentValue.value).then((result) => {
      const experiment = result.data.data;

      setFarmingType(cropTypes.find(option => option.value === experiment.crop));
      setExperimentType(experimentTypes.find(option => option.value === experiment.exp_type));
      setUnitySize(unitySizes.find(option => option.value === experiment.size_category));
    })
  }

  const handleClearRefExperiment = () => {
    setExperimentType("")
    setFarmingType("")
    setUnitySize("")
  }

  const handleCloseModal = () => {
    setConfirmLeavePageModal(false)
  }

  const goBack = () => {
    if (experiment_uuid) {
      navigate('/')
    } else {
      const pageValues = [experimentType, farmingType, unitySize, selectedActiveExperiment, scanTime, endDate, plantingDate, experimentName, description]
      const pageModifications = pageValues.some(e => !!e)

      if (pageModifications) {
        setConfirmLeavePageModal(true)
      } else {
        navigate('/')
      }
    }
  }

  const confirmModal = () => {
    navigate('/')
  }

  const updateInactiveExperiments = useCallback(async () => {
    const data = {};
    try {
      const res = await listExperiments(
        selectedCompany?.name,
        selectedGreenhouse?.name,
        false,
        null,
        null,
        subWeeks(new Date(), 1),
      );
      for (const exp of res.data) {
        if (!exp.end_date) {
          continue;
        }

        const item = {
          ...exp,
          start_date: exp.exp_start_date && parse(exp.exp_start_date, 'yyyyMMdd', new Date()),
          end_date: exp.end_date && parse(exp.end_date, 'yyyyMMdd', new Date()),
        };
        data[exp.uuid] = item;
      }
    } catch (ex) {
      console.error('ex:', ex);
      return;
    }

    setInactiveExperiments(data);
  }, [setInactiveExperiments, selectedCompany?.name, selectedGreenhouse?.name]);

  const handleCreatEditExperiment = async () => {
    const companyName = selectedCompany?.name;
    const greenhouseName = selectedGreenhouse?.name;

    const traysUUID = selectedTrayLayers && selectedTrayLayers.map(e => e.properties.uuid)

    if (experiment_uuid) {
      try {
        const experimentUpdated = {
          "active": true,
          "greenhouse": selectedGreenhouse && greenhouseName,
          "name": experimentName && experimentName,
          "crop": farmingType && farmingType.value,
          "schedule": scanTime && scanTime.map(e => e.value),
          "start_date": startDate && format(startDate, 'yyyyMMdd'),
          "end_date": endDate ? format(endDate, 'yyyyMMdd') : null,
          "plant_date": plantingDate && format(plantingDate, 'yyyyMMdd'),
          "description": description,
          "tray_uuids": traysUUID,
          "previous_experiment_uuid": selectedActiveExperiment && selectedActiveExperiment.value,
          "exp_type": experimentType.value,
          "size_category": unitySize.value,
          "use_qrcode": true
        };

        const res = await updateExperiment(companyName, greenhouseName, experiment_uuid, experimentUpdated);

        toast.success("O experimento foi atualizado com sucesso!");

        if (res.status !== 200) {
          toast.warning(res.data.message)
          throw res;
        }

      } catch (ex) {
        toast.error("Falha ao atualizar experimento.");
      }

    } else {
      try {
        const experiment = {
          "active": true,
          "greenhouse": selectedGreenhouse && greenhouseName,
          "name": experimentName && experimentName,
          "crop": farmingType && farmingType.value,
          "schedule": scanTime && scanTime.map(e => e.value),
          "start_date": startDate && format(startDate, 'yyyyMMdd'),
          "end_date": endDate ? format(endDate, 'yyyyMMdd') : null,
          "plant_date": plantingDate ? format(plantingDate, 'yyyyMMdd') : null,
          "description": description ? description : null,
          "tray_uuids": traysUUID,
          "previous_experiment_uuid": selectedActiveExperiment && selectedActiveExperiment.value,
          "exp_type": experimentType.value,
          "size_category": unitySize.value,
          "use_qrcode": true
        };

        navigate("/")
        toast.success("O experimento foi cadastrado com sucesso!");

        const res = await createExperiment(companyName, experiment);

        if (res.status !== 201) {
          toast.warning(res.data.message)
          throw res;
        }
      } catch (ex) {
        console.error('ex:', ex);
        toast.error("Falha ao cadastrar experimento.");
      }
    }
  };


  useEffect(() => {
    updateInactiveExperiments();
  }, [
    updateInactiveExperiments,
  ]);

  function compareTimestamp(timestamp) {
    const timestampDate = new Date(timestamp);

    const updateDifference = () => {
      const now = new Date();
      const diffInMs = now - timestampDate;
      const diffInMinutes = Math.floor(diffInMs / 1000 / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const remainingMinutes = diffInMinutes % 60;

      if (diffInHours >= 48) {
        setExperimentTime(false)
        clearInterval(interval);
        return false;
      }
      setExperimentTime(`${48 - diffInHours - 1}h${60 - remainingMinutes}m`)
    };

    const interval = setInterval(updateDifference, 60 * 1000);

    updateDifference();
  }


  useEffect(() => {
    if (selectedCompany && selectedGreenhouse) {
      const fetchOptions = async () => {

        const companyName = selectedCompany.name;
        const greenhouseName = selectedGreenhouse.name;

        const inactiveExperimentsListObj = inactiveExperiments && Object.entries(inactiveExperiments).map(e => ({
          label: e[1].name,
          value: e[1].uuid
        }))

        const unitySizeOptions = await handleSelectOptions(companyName, greenhouseName, 'unit_size_categories');
        const experimentTypeOptions = await handleSelectOptions(companyName, greenhouseName, 'experiment_types');
        const cropTypeOptions = await handleSelectOptions(companyName, greenhouseName, 'crop_types');
        const allowedScanTimeOptions = await handleSelectOptions(companyName, greenhouseName, 'allowed_scan_times');

        const translatedUnitySizeOptions = unitySizeOptions.map(option => ({
          label: t(option.label),
          value: option.value
        }))
        const translatedExperimentTypeOptions = experimentTypeOptions.map(option => ({
          label: t(option.label),
          value: option.value
        }))
        const translatedCropTypeOptions = cropTypeOptions.map(option => ({
          label: t(option.label),
          value: option.value
        }))

        setUnitySizes(translatedUnitySizeOptions);
        setExperimentTypes(translatedExperimentTypeOptions);
        setCropTypes(translatedCropTypeOptions);
        setAllowedScanTimes(allowedScanTimeOptions);
        setInactiveExperimentsList(inactiveExperimentsListObj)
        setStartDate(new Date())

        if (experiment_uuid) {
          getExperiment(companyName, greenhouseName, experiment_uuid).then((result) => {
            const experiment = result.data.data;

            setInactiveExperimentsList(inactiveExperimentsListObj)
            setExperimentName(experiment.name);
            setDescription(experiment.description);
            setFarmingType(cropTypes.find(option => option.value === experiment.crop));
            setExperimentType(experimentTypes.find(option => option.value === experiment.exp_type));
            setUnitySize(unitySizes.find(option => option.value === experiment.size_category));
            setSelectedActiveExperiment(inactiveExperimentsListObj?.find(option => option.value === experiment.previous_experiment_uuid))
            setScanTime(experiment.schedule.map(time => allowedScanTimes.find(option => option.value === time)))
            setStartDate(experiment.exp_start_date ? parse(experiment.exp_start_date, 'yyyyMMdd', new Date()) : null)
            setEndDate(experiment.end_date ? parse(experiment.end_date, 'yyyyMMdd', new Date()) : null);
            setPlantingDate(experiment.plant_date ? parse(experiment.plant_date, 'yyyyMMdd', new Date()) : null);

            compareTimestamp(experiment.timestamp)

            const now = new Date();
            const exp_date = new Date(experiment.timestamp);
            const adjusted_date = addDays(exp_date, 2);
            setIsEditabled(isBefore(adjusted_date, now))
          });
        }
      };
      fetchOptions();
    }
  }, [selectedCompany, selectedGreenhouse, inactiveExperiments])

  const requiredInputs = scanTime && scanTime.length > 0 && experimentType && unitySize && farmingType && experimentName ? true : false

  return (
    <>
      <div className="Experiment">
        <div className="Header">
          <div className="PageNav" onClick={() => goBack()}>
            <RiArrowLeftSLine size="24" fill="#555" />
            <Text className="bold big gray2">{experiment_uuid ? "Editar experimento" : "Cadastrar experimento"}</Text>
          </div>
          {window.innerWidth > 980 &&
            <Button disabled={!experiment_uuid && !requiredInputs} onClick={() => handleCreatEditExperiment()} className="primary">
              <Text className="bold white">{experiment_uuid ? "Salvar alterações" : "Criar experimento"}</Text>
            </Button>
          }
        </div >
        <div className="Content">
          <div className="Group">
            <Text className="bold secondaryTxt">Dados do experimento</Text>
            <div className="Inputs">
              <Input
                label={<Text className="bold smallTxt">Nome do experimento</Text>}
                time={experiment_uuid && experimentTime}
                placeholder="Digite o nome do experimento"
                value={experimentName}
                onChange={(e) => onChange(e.target.value, setExperimentName)}
                required
                disabled={isEditabled}
              />
              <Select
                label={<Text className="bold smallTxt">Tipo de experimento</Text>}
                time={experiment_uuid && experimentTime}
                options={experimentTypes}
                value={experimentType}
                disabled={selectedActiveExperiment || isEditabled}
                placeholder="Selecione o tipo de experimento"
                onChange={(e) => onChange(e, setExperimentType)}
                isLoading={experiment_uuid && (!experimentTypes || !inactiveExperimentsList)}
                required
              />
              <Select
                label={<Text className="bold smallTxt">Tipo de cultivo</Text>}
                time={experiment_uuid && experimentTime}
                options={cropTypes}
                value={farmingType}
                disabled={selectedActiveExperiment || isEditabled}
                placeholder="Selecione o tipo de cultivo"
                onChange={(e) => onChange(e, setFarmingType)}
                isLoading={experiment_uuid && (!cropTypes || !inactiveExperimentsList)}
                required
              />
              <Select
                label={<Text className="bold smallTxt">Tamanho da unidade</Text>}
                info={
                  <div className='d-flex align-items-start flex-column'>
                    <Text className="tinyTxt" color="gray2">Pequena: 1 foto</Text>
                    <Text className="tinyTxt" color="gray2">Média: 4 fotos</Text>
                  </div>
                }
                tooltipInfoId="unitySize"
                time={experiment_uuid && experimentTime}
                options={unitySizes}
                value={unitySize}
                disabled={selectedActiveExperiment || isEditabled}
                placeholder="Selecione o tamanho"
                onChange={(e) => onChange(e, setUnitySize)}
                isLoading={experiment_uuid && (!unitySizes || !inactiveExperimentsList)}
                required
              />
              <Select
                label={<Text className="bold smallTxt">Experimento de referência</Text>}
                info={
                  <Text className="tinyTxt" color="gray2">Experimento destinado à continuidade das avaliações após a aplicação de um tratamento que resultou na movimentação das unidades experimentais de suas posições originais. Os dados do experimento de referência e do atual serão mantidos em conjunto, baseando-se no croqui do experimento, com os códigos inseridos em cada unidade experimental. O número de unidades experimentais no novo experimento deve ser igual ao do experimento de referência, e os códigos utilizados ao inserir o novo croqui devem ser os mesmos do experimento de referência.</Text>
                }
                tooltipInfoId="refExperiment"
                time={experiment_uuid && experimentTime}
                options={inactiveExperimentsList}
                value={selectedActiveExperiment}
                placeholder="Selecione o experimento"
                onChange={(e) => onChangeRefExperiment(e)}
                clear={() => handleClearRefExperiment()}
                isLoading={!inactiveExperimentsList}
                disabled={isEditabled}
              />

            </div>
            <Textarea
              label={<Text className="bold smallTxt">Descrição</Text>}
              placeholder="Descreva aqui o experimento"
              value={description}
              onChange={(e) => onChange(e.target.value, setDescription)}
            />
          </div>
          <div className="Group">
            <Text className="bold secondaryTxt">Dados do monitoramento</Text>
            <div className="Inputs">
              <DatePicker
                label={<Text className="bold smallTxt">Data de início</Text>}
                placeholder="Selecione a data de início"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                maxDate={plantingDate}
                disabled
              />
              <Select
                label={<Text className="bold smallTxt">Horários de varredura</Text>}
                options={allowedScanTimes}
                value={scanTime}
                isLoading={experiment_uuid && !allowedScanTimes}
                placeholder="Selecione os horários"
                isMulti
                closeMenuOnSelect={false}
                onChange={(e) => onChange(e, setScanTime)}
                required
              />
              <DatePicker
                label={<Text className="bold smallTxt">Data de plantio</Text>}
                placeholder="Selecione a data de plantio"
                value={plantingDate}
                onChange={(date) => setPlantingDate(date)}
                minDate={startDate}
                maxDate={endDate}
              />
              <DatePicker
                label={<Text className="bold smallTxt">Data de finalização</Text>}
                placeholder="Selecione a data de finalização"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                minDate={plantingDate}
              />
            </div>
          </div>
          {window.innerWidth < 980 &&
            <Button disabled={!experiment_uuid && !requiredInputs} onClick={() => handleCreatEditExperiment()} className="primary fluid">
              <Text className="bold white">{experiment_uuid ? "Salvar alterações" : "Criar experimento"}</Text>
            </Button>
          }
        </div>
        {confirmLeavePageModal && (
          <Modal
            title="Você tem alterações não salvas"
            confirm="Retornar ao mapa de experimentos"
            dangerModal
            handleCloseModal={handleCloseModal}
            confirmModal={confirmModal}>
            <Text>Se você sair desta página, as alterações realizadas não serão salvas. Tem certeza de que deseja retornar ao mapa de experimentos?</Text>
          </Modal>
        )}
      </div>

    </>
  )
}
export default Experiment;