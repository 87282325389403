import Text from 'components/Text';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { RiInformation2Line, RiTimeLine } from "react-icons/ri";
import ReactSelect from 'react-select'
import { getRootVar } from 'utils/generalFunctions'
import { Tooltip } from 'react-tooltip';

export const Select = ({
  options,
  value,
  placeholder,
  label,
  onChange,
  disabled,
  isMulti,
  fluid,
  clear,
  isLoading,
  required,
  themeProp,
  small,
  info,
  time,
  tooltipInfoId,
  action,
  ...props
}) => {
  const theme = themeProp ? themeProp : document.querySelector("body").getAttribute("data-theme");
  const { t } = useTranslation()

  const handleChange = (selectedOption) => {
    if (selectedOption === null) {
      clear();
    } else {
      onChange(selectedOption);
    }
  }

  const listLength = options?.length

  return (
    <div className='label-title' style={{
      cursor: disabled ? "not-allowed" : "pointer",
      opacity: disabled ? ".6" : "1"
    }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className={`d-flex align-items-center gap-2 ${action && "justify-content-between"}`} style={{ width: "100%" }}>
          {label &&
            <div className="d-flex gap-1">
              <div>{label}</div>
              {required && <Text className="smallTxt" color={getRootVar("--red")}>*</Text>}
            </div>
          }
          {info && (
            <>
              <div data-tooltip-id={tooltipInfoId}>
                <RiInformation2Line color={getRootVar("--gray3")} size="14" />
              </div>
              <Tooltip
                id={tooltipInfoId}
                style={{ backgroundColor: getRootVar("white"), border: "1px solid red", boxShadow: getRootVar("shadow") }}>
                {info}
              </Tooltip>
            </>
          )}
          {action && (
            <Text onClick={action} className="tinyTxt" color={getRootVar("--secondary")} autoTranslate="false">{t("Selecionar tudo ({{listLength}})", { listLength })}</Text>
          )}
        </div>
        {time && (
          <>
            <div data-tooltip-id={time} className="d-flex align-items-center gap-1">
              <RiTimeLine color={getRootVar("--gray3")} size="14" />
              <Text className="tinyTxt" color={getRootVar("--gray3")}>{time}</Text>
            </div>
            <Tooltip
              id={time}
              style={{ backgroundColor: getRootVar("white"), border: "1px solid red", boxShadow: getRootVar("shadow") }}>
              <Text className="tinyTxt">{t('Você tem {{time}} para editar essa informação. Após esse período, o campo será bloqueado.', { time })}</Text>
            </Tooltip>
          </>
        )}
      </div>
      <ReactSelect
        {...props}
        options={options}
        onChange={handleChange}
        placeholder={t(placeholder)}
        value={value}
        isMulti={isMulti}
        isClearable={clear}
        isDisabled={disabled}
        clearValue={clear}
        isLoading={isLoading}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? getRootVar("--secondary") : theme === "dark" ? "none" : getRootVar("--gray4"),
            boxShadow: 'none',
            color: getRootVar("--altWhite"),
            width: fluid && '100%',
            height: small ? "30px" : "43px",
            minHeight: 'auto',
            fontSize: small ? getRootVar("--small") : getRootVar("--normal"),
            borderRadius: '8px',
            padding: small ? "0px" : '2px 4px',
            display: "flex",
            flexWrap: "nowrap",
            overflow: 'hidden',
            backgroundColor: disabled ? getRootVar("--altWhite") : theme === "dark" && getRootVar("--altWhite"),
            opacity: theme === "dark" && disabled && ".7",
            '&:hover': {
              borderColor: "none"
            },
            ...(window.innerWidth <= 980 && {
              height: "48px",
            }),
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            flexWrap: "nowrap",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: getRootVar('--gray2')
          }),
          multiValue: (baseStyles) => ({
            ...baseStyles,
            minWidth: "70px",
            backgroundColor: theme === "dark" ? getRootVar("--white") : getRootVar("--altWhite"),
            justifyContent: "space-between"
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: theme === "dark" ? getRootVar("--altWhite") : 'white'
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused
              ? theme === "dark"
                ? getRootVar("--gray4")
                : getRootVar("--altWhite")
              : 'transparent',
            color: state.isSelected ? getRootVar("--secondary") : getRootVar("--gray1"),
            '&:hover': {
              backgroundColor: theme === "dark" ? getRootVar("--gray4") : getRootVar("--altWhite"),
            },
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: getRootVar("--gray3"),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }),
          menuPortal: (baseStyles) => ({ ...baseStyles, zIndex: 9999 })
        }}
      />
    </div>
  )
}